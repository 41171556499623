/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

// route/routeArticle.ts
import { RouteObject, useNavigate } from 'react-router-dom';
import ArticleListPage from '../../logic/auto/article/ArticleListPage';
import ArticleDetailPage from '../../logic/auto/article/ArticleDetailPage';
import { Button } from 'antd';

const articleRoutes: RouteObject[] = [
  { path: '/test/article-list', element: <ArticleListPage /> },
  { path: '/test/article-detail/:id', element: <ArticleDetailPage /> },
];

const articleAdminRoutes: RouteObject[] = [
  { path: '/test/admin/article-list', element: <ArticleListPage /> },
  { path: '/test/admin/article-detail/:id', element: <ArticleDetailPage /> },
];

// 测试入口组件
export const TestEntry: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button type="default" size="large" onClick={() => navigate('/test/article-list')}>
      ArticleList Test
    </Button>
  );
};

// 测试入口menu
export const menuData: {} = {
  key : "/test/admin/article-list",
  path: "/test/admin/article-list",
  name: "Article管理"
}

export default { routes: articleRoutes, adminRoutes: articleAdminRoutes, TestEntry, menuData };
