import wxNativeIcon from "../../assets/cashier2/wx_native.svg";
import wxBarIcon from "../../assets/cashier2/wx_bar.svg";
import wxJsapiIcon from "../../assets/cashier2/wx_jsapi.svg";
import wxH5Icon from "../../assets/cashier2/wx_h5.svg";
import aliQrIcon from "../../assets/cashier2/ali_qr.svg";
import aliBarIcon from "../../assets/cashier2/ali_bar.svg";
import aliJsapiIcon from "../../assets/cashier2/ali_jsapi.svg";
import aliPcIcon from "../../assets/cashier2/ali_pc.svg";
import aliWapIcon from "../../assets/cashier2/ali_wap.svg";
import autoBarIcon from "../../assets/cashier2/auto_bar.svg";
import webCashierIcon from "../../assets/cashier2/web_cashier.svg";
import qrCashierLiteIcon from "../../assets/cashier2/qr_cashier_wx_lite.svg";
import qrCashierJsapiIcon from "../../assets/cashier2/qr_cashier_wx_jsapi.svg";
import qrCashierIcon from "../../assets/cashier2/qr_cashier.svg";


export const paymentMethods: any[] = [
    {
      category: "微信支付",
      methods: [
        { code: "WX_NATIVE", name: "微信二维码", icon: wxNativeIcon},
        { code: "WX_BAR", name: "微信条码", icon: wxBarIcon},
        { code: "WX_QR_CASHIER", name: "公众号/小程序", icon: wxJsapiIcon },
        { code: "WX_H5", name: "微信H5", icon: wxH5Icon, isH5: true},
      ],
    },
    {
      category: "支付宝支付",
      methods: [
        { code: "ALI_QR", name: "支付宝二维码", icon: aliQrIcon },
        { code: "ALI_BAR", name: "支付宝条码", icon: aliBarIcon },
        { code: "ALI_QR_CASHIER", name: "支付宝生活号", icon: aliJsapiIcon },
        { code: "ALI_PC", name: "支付宝PC网站", icon: aliPcIcon },
        { code: "ALI_WAP", name: "支付宝WAP", icon: aliWapIcon, isH5: true },
      ],
    },
    {
      category: "聚合支付",
      methods: [
        { code: "AUTO_BAR", name: "聚合被扫(商家扫用户)", icon: autoBarIcon },
        { code: "WEB_CASHIER", name: "web收银台", icon: webCashierIcon },
        { code: "QR_CASHIER_LITE", name: "聚合主扫(小程序)", icon: qrCashierLiteIcon, entryPageType: "lite" },
        { code: "QR_CASHIER_H5", name: "聚合主扫(公众号)", icon: qrCashierJsapiIcon, entryPageType: "h5" },
        { code: "QR_CASHIER_STATIC", name: "聚合主扫(静态码)", icon: qrCashierIcon, isStaticCode: true },
      ],
    },
  ];