/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import type { ColumnsType } from 'antd/es/table';
import { Book } from './Book';

// 生成 Book 表格列配置
export const tableColumnsBook: ColumnsType<Book> = [
  {
    title: '书籍ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '作者',
    dataIndex: 'author',
    key: 'author',
  },
  {
    title: 'ISBN',
    dataIndex: 'isbn',
    key: 'isbn',
  },
  {
    title: '书籍标签',
    dataIndex: 'tags',
    key: 'tags',
  },
  {
    title: '封面',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (url: string) => <img src={url} alt="图片" style={{ width: '50px' }} />,
  },
  {
    title: '出版日期',
    dataIndex: 'publishedDate',
    key: 'publishedDate',
    render: (value: string) => new Date(value).toLocaleDateString(),
  },
  {
    title: '书籍价格',
    dataIndex: 'price',
    key: 'price',
  }
];
