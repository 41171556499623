// 模拟的域名数据
let mockDomainData = [
    {
      id: 1,
      domainName: 'example.com',
      registrar: 'GoDaddy',
      registrationDate: '2023-01-15',
      expirationDate: '2025-01-15',
      ownerName: 'John Doe',
      ownerEmail: 'john.doe@example.com',
      status: 'active',
      nameservers: 'ns1.example.com,ns2.example.com',
      imageUrl: 'https://placehold.co/100X100/png',
      createdDate: '2024-09-04T03:36:36.000+00:00',
      updatedDate: '2024-09-04T03:36:36.000+00:00',
    },
    {
      id: 2,
      domainName: 'hainei.com',
      registrar: 'Alibaba Cloud Computing (Beijing) Co., Ltd.',
      registrationDate: '2006-07-29',
      expirationDate: '2026-07-29',
      ownerName: 'Unknown',
      ownerEmail: 'unknown@hainei.com',
      status: 'ok',
      nameservers: 'DNS1.HICHINA.COM,DNS2.HICHINA.COM',
      imageUrl: 'https://placehold.co/100X100/png',
      createdDate: '2024-09-04T03:36:36.000+00:00',
      updatedDate: '2024-09-04T03:36:36.000+00:00',
    }
  ];
  
  // 获取域名列表（模拟的分页）
  export const getDomainMockData = (page: number, pageSize: number): Promise<any> => {
    const start = (page - 1) * pageSize;
    const records = mockDomainData.slice(start, start + pageSize);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          errorCode: 0,
          message: 'Request processed successfully',
          data: {
            records,
            total: mockDomainData.length,
            size: pageSize,
            current: page,
          },
        });
      }, 500);
    });
  };
  
  // 模拟添加域名
  export const mockAddDomain = (newDomain: any): Promise<any> => {
    return new Promise((resolve) => {
      mockDomainData.push({ ...newDomain, id: mockDomainData.length + 1 });
      setTimeout(() => resolve({ errorCode: 0, message: 'Domain added successfully' }), 500);
    });
  };
  
  // 模拟编辑域名
  export const mockEditDomain = (id: number, updatedDomain: any): Promise<any> => {
    return new Promise((resolve) => {
      mockDomainData = mockDomainData.map((domain) => (domain.id === id ? { ...domain, ...updatedDomain } : domain));
      setTimeout(() => resolve({ errorCode: 0, message: 'Domain updated successfully' }), 500);
    });
  };
  
  // 模拟删除域名
  export const mockDeleteDomain = (id: number): Promise<any> => {
    return new Promise((resolve) => {
      mockDomainData = mockDomainData.filter((domain) => domain.id !== id);
      setTimeout(() => resolve({ errorCode: 0, message: 'Domain deleted successfully' }), 500);
    });
  };
  
  // 获取域名详情（模拟）
  export const mockFetchDomainDetail = (id: number): Promise<any> => {
    const domain = mockDomainData.find((domain) => domain.id === id);
  
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (domain) {
          resolve({ data: domain }); // 返回找到的域名
        } else {
          reject(new Error('Domain not found')); // 未找到域名时返回错误
        }
      }, 500); // 模拟延迟500ms
    });
  };