import React from 'react';

const cardClassName = "bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300";
const buttonClassName = "bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300";
const inactivePaginationButtonClassName = "px-4 py-2 rounded-md bg-gray-300 text-gray-700 hover:bg-gray-400 transition-colors duration-300";
const activePaginationButtonClassName = "px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300";
const testImageUrl = "https://via.placeholder.com/150";

// Template data
const templates = [
  { id: 1, name: "模板名称1", description: "模板简要描述，突出特点和用途。", imageUrl: testImageUrl },
  { id: 2, name: "模板名称2", description: "模板简要描述，突出特点和用途。", imageUrl: testImageUrl },
  { id: 3, name: "模板名称3", description: "模板简要描述，突出特点和用途。", imageUrl: testImageUrl },
  // Add more template data
];

const recommendedTemplates = [
  { id: 1, name: "推荐模板1", description: "推荐模板简要描述。", imageUrl: testImageUrl },
  { id: 2, name: "推荐模板2", description: "推荐模板简要描述。", imageUrl: testImageUrl },
  { id: 3, name: "推荐模板3", description: "推荐模板简要描述。", imageUrl: testImageUrl },
  { id: 4, name: "推荐模板4", description: "推荐模板简要描述。", imageUrl: testImageUrl },
  // Add more recommended template data
];

const Templates: React.FC = () => {
  return (
    <div className="bg-soft-main flex flex-col">
      <div>
        <div className='max-w-7xl mx-auto px-4 flex flex-col justify-between items-center bg-gray-50'>

          {/* Recommended Templates Section */}
          <section className="py-10 w-full">
            <div className="container mx-auto text-center">
              <h2 className="text-xl mb-6">推荐模板</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {recommendedTemplates.map(template => (
                  <div key={template.id} className={cardClassName}>
                    <img src={template.imageUrl} alt={template.name} className="w-full h-40 object-cover rounded-lg mb-3" />
                    <h3 className="text-base mb-2">{template.name}</h3>
                    <p className="text-gray-600 mb-4 text-sm">{template.description}</p>
                    <a href="#" className={buttonClassName}>查看详情</a>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Pagination */}
          <section className="py-10 w-full">
            <div className="container mx-auto text-center">
              <div className="inline-flex items-center space-x-2">
                <a href="#" className={inactivePaginationButtonClassName}>上一页</a>
                <a href="#" className={activePaginationButtonClassName}>1</a>
                <a href="#" className={inactivePaginationButtonClassName}>2</a>
                <a href="#" className={inactivePaginationButtonClassName}>3</a>
                <a href="#" className={inactivePaginationButtonClassName}>下一页</a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Templates;