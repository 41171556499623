import React, { useState } from 'react';
import { Button, message } from 'antd';
import TestPickerModal from '../../component/test/TestPickerModal';
import dayjs from 'dayjs';

const PickerTestPage: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>({});

  const initialData = {
    testDate: dayjs('2024-09-01', 'YYYY-MM-DD'),
    testTime: dayjs('12:30:00', 'HH:mm:ss'),
    testRange: [dayjs('2023-09-01', 'YYYY-MM-DD'), dayjs('2023-09-10', 'YYYY-MM-DD')],
    testColor: '#ff0000',
    testNumber: 42,
    testMonth: dayjs('2023-09', 'YYYY-MM'),
    testSelect: 'option1',
    testCascader: ['zhejiang', 'hangzhou'],
  };

  const handleModalSubmit = (values: any) => {
    setSelectedValues(values);
    message.success('选择的值已保存');
    setVisible(false);
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        打开测试各种 Picker 的 Modal
      </Button>

      <TestPickerModal
        visible={visible}
        initialData={initialData}
        onSubmit={handleModalSubmit}
        onCancel={handleModalCancel}
      />

      {/* 显示选中的值 */}
      {selectedValues.testDate && (
        <p>已选择的日期是: <strong>{selectedValues.testDate}</strong></p>
      )}
      {selectedValues.testTime && (
        <p>已选择的时间是: <strong>{selectedValues.testTime}</strong></p>
      )}
      {selectedValues.testRange && (
        <p>已选择的日期范围是: <strong>{selectedValues.testRange[0]}</strong> - <strong>{selectedValues.testRange[1]}</strong></p>
      )}
      {selectedValues.testMonth && (
        <p>已选择的月份是: <strong>{selectedValues.testMonth}</strong></p>
      )}
      {selectedValues.testColor && (
        <p>已选择的颜色是: <strong>{selectedValues.testColor}</strong></p>
      )}
      {selectedValues.testNumber && (
        <p>已选择的数字是: <strong>{selectedValues.testNumber}</strong></p>
      )}
      {selectedValues.testSelect && (
        <p>已选择的选项是: <strong>{selectedValues.testSelect}</strong></p>
      )}
      {selectedValues.testCascader && (
        <p>已选择的级联是: <strong>{selectedValues.testCascader.join(' / ')}</strong></p>
      )}
    </div>
  );
};

export default PickerTestPage;