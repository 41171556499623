/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

// route/routeUser.ts
import { RouteObject, useNavigate } from 'react-router-dom';
import UserListPage from '../../logic/auto/user/UserListPage';
import UserDetailPage from '../../logic/auto/user/UserDetailPage';
import { Button } from 'antd';

const userRoutes: RouteObject[] = [
  { path: '/test/user-list', element: <UserListPage /> },
  { path: '/test/user-detail/:id', element: <UserDetailPage /> },
];

const userAdminRoutes: RouteObject[] = [
  { path: '/test/admin/user-list', element: <UserListPage /> },
  { path: '/test/admin/user-detail/:id', element: <UserDetailPage /> },
];

// 测试入口组件
export const TestEntry: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button type="default" size="large" onClick={() => navigate('/test/user-list')}>
      UserList Test
    </Button>
  );
};

// 测试入口menu
export const menuData: {} = {
  key : "/test/admin/user-list",
  path: "/test/admin/user-list",
  name: "User管理"
}

export default { routes: userRoutes, adminRoutes: userAdminRoutes, TestEntry, menuData };
