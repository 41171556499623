/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import type { ColumnsType } from 'antd/es/table';
import { UserAuth } from './UserAuth';

// 生成 UserAuth 表格列配置
export const tableColumnsUserAuth: ColumnsType<UserAuth> = [
  {
    title: '认证ID',
    dataIndex: 'authId',
    key: 'authId',
  },
  {
    title: '用户ID',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: '登录类型',
    dataIndex: 'identityType',
    key: 'identityType',
    // 使用 value_map 映射值
    render: (value: number) => {
      switch (value) {
        case 1:
          return '登录账号';
        case 2:
          return '手机号';
        case 3:
          return '邮箱';
        case 10:
          return '微信';
        case 11:
          return 'QQ';
        case 12:
          return '支付宝';
        case 13:
          return '微博';
        default:
          return '未知类型';
      }
    },
  },
  {
    title: '认证标识（用户名 ｜open_id）',
    dataIndex: 'identifier',
    key: 'identifier',
  },
  {
    title: '密码凭据',
    dataIndex: 'credential',
    key: 'credential',
  },
  {
    title: 'SALT',
    dataIndex: 'salt',
    key: 'salt',
  },
  {
    title: '所属系统',
    dataIndex: 'sysType',
    key: 'sysType',
  }
];
