import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from '../component/Header';
import AppFooter from '../component/Footer';

const MainLayout: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <AppHeader />
      <main className="flex-grow flex flex-col justify-center bg-soft-main">
        <Outlet /> {/* This is where the nested routes (children) will be rendered */}
      </main>
      <AppFooter />
    </div>
  );
};

export default MainLayout;