import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/request';
import { getDomainMockData, mockAddDomain, mockEditDomain, mockDeleteDomain, mockFetchDomainDetail } from './mockDomainData'; // 导入 Mock 函数

const USE_MOCK_DATA = true; // 切换 Mock 数据标志

// 获取域名列表的请求
export const fetchDomainList = (page: number, pageSize: number) => {
  if (USE_MOCK_DATA) {
    return getDomainMockData(page, pageSize);
  }
  return getRequest('/api/v1/test/domain/page', {
    page,
    size: pageSize,
  });
};

// 添加新域名的请求
export const addDomain = (data: any) => {
  if (USE_MOCK_DATA) {
    return mockAddDomain(data);
  }
  return postRequest('/api/v1/test/domain', data);
};

// 编辑域名的请求
export const editDomain = (id: number, data: any) => {
  if (USE_MOCK_DATA) {
    return mockEditDomain(id, data);
  }
  return putRequest(`/api/v1/test/domain/${id}`, data);
};

// 删除域名的请求
export const deleteDomain = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockDeleteDomain(id);
  }
  return deleteRequest(`/api/v1/test/domain/${id}`);
};

// 获取域名详情的请求
export const fetchDomainDetail = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockFetchDomainDetail(id);
  }
  return getRequest(`/api/v1/test/domain/${id}`);
};