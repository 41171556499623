/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/request';
import {
  getBookMockData,
  mockAddBook,
  mockEditBook,
  mockDeleteBook,
  mockFetchBookDetail
} from './mockBookData'; // 导入 Mock 函数

const USE_MOCK_DATA = true; // 切换 Mock 数据标志

// 获取Book列表的请求
export const fetchBookList = (page: number, pageSize: number) => {
  if (USE_MOCK_DATA) {
    return getBookMockData(page, pageSize);
  }
  return getRequest('/api/v1/test/book/page', {
    page,
    size: pageSize,
  });
};

// 添加新Book的请求
export const addBook = (data: any) => {
  if (USE_MOCK_DATA) {
    return mockAddBook(data);
  }
  return postRequest('/api/v1/test/book', data);
};

// 编辑Book的请求
export const editBook = (id: number, data: any) => {
  if (USE_MOCK_DATA) {
    return mockEditBook(id, data);
  }
  return putRequest(`/api/v1/test/book/${id}`, data);
};

// 删除Book的请求
export const deleteBook = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockDeleteBook(id);
  }
  return deleteRequest(`/api/v1/test/book/${id}`);
};

// 获取Book详情的请求
export const fetchBookDetail = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockFetchBookDetail(id);
  }
  return getRequest(`/api/v1/test/book/${id}`);
};
