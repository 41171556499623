import React, { useRef } from 'react';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react'; // 导入 QRCode 生成组件
import { saveAs } from 'file-saver'; // 用于下载二维码
import { Button } from 'antd';

// 定义 QrCode 组件的属性类型
type QrCodeProps = {
  value: string; // 二维码的内容
  width?: number; // 二维码的宽度
  size?: number; // 二维码的尺寸
  logo?: string; // 可选的嵌入 logo
  level?: 'L' | 'M' | 'Q' | 'H'; // 二维码的纠错等级
  tag?: 'canvas' | 'svg'; // 指定生成二维码的方式，canvas 或 svg
  onDone?: (data: { url: string }) => void; // 成功生成二维码时的回调
  onError?: (error: any) => void; // 生成二维码失败时的回调
};

// QrCode 组件实现
const QrCode: React.FC<QrCodeProps> = ({
  value,
  width = 200, // 默认宽度为200
  size = 200, // 默认尺寸为200
  logo,
  level = 'L', // 默认纠错等级为 'L'
  tag = 'canvas',
  onDone,
  onError,
}) => {
  const qrRef = useRef<HTMLCanvasElement | null>(null); // 用于存储 canvas 或 svg 的引用

  // 下载二维码的函数
  const downloadQRCode = (fileName: string = 'qrcode.png') => {
    try {
      const canvas = qrRef.current as HTMLCanvasElement; // 获取 canvas 元素
      const url = canvas.toDataURL(); // 将 canvas 转为数据 URL
      saveAs(url, fileName); // 下载二维码
    } catch (error) {
      if (onError) onError(error); // 如果下载过程中出错，调用 onError 回调
    }
  };

  return (
    <div>
      {/* 根据 tag 属性决定使用 canvas 还是 svg 生成二维码 */}
      {tag === 'canvas' ? (
        <QRCodeCanvas
          ref={qrRef} // 设置 canvas 的引用
          value={value} // 二维码的内容
          size={size} // 二维码的尺寸，使用 size 属性
          level={level} // 纠错等级
          imageSettings={
            logo
              ? {
                  src: logo, // 嵌入的 logo
                  height: 40,
                  width: 40,
                  excavate: true, // 保证二维码的可扫描性
                }
              : undefined
          }
        />
      ) : (
        <QRCodeSVG
          ref={qrRef as any} // 设置 svg 的引用
          value={value} // 二维码的内容
          size={size} // 二维码的尺寸，使用 size 属性
          level={level} // 纠错等级
        />
      )}

      {/* 下载按钮 */}
      {/* <Button onClick={() => downloadQRCode('qrcode.png')}>下载二维码</Button> */}
    </div>
  );
};

export default QrCode;