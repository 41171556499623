import React, { useState } from 'react';
import { Modal } from 'antd';
import QrCode from './QrCode'; // 需要确保这个组件存在

type QrCodeModalProps = {
  visible: boolean;
  qrUrl: string;
  bottomTitle: string;
  onCancel: () => void;
};

const CashierQrCodeModal: React.FC<QrCodeModalProps> = ({ visible, qrUrl, bottomTitle, onCancel }) => {
  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal open={visible} title="扫码支付" footer={null} onCancel={handleCancel} width={250}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 15 }}>
        <QrCode value={qrUrl} size={180} level="H" />
        <div style={{ marginTop: '15px', marginBottom: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {bottomTitle}
        </div>
      </div>
    </Modal>
  );
};

export default CashierQrCodeModal;