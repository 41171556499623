import React, { useEffect } from 'react';
import { Modal, Form, Input, DatePicker } from 'antd';
import dayjs from 'dayjs';

interface AddDomainModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues?: any;  // 支持传递初始值
}

const DomainAddOrEditModal: React.FC<AddDomainModalProps> = ({ visible, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm();

  const mockFlag = false;
  // 如果没有传入 initialValues，则使用默认的 mock 数据
  const mockInitialValues = {
    domainName: 'example.com',
    registrar: 'GoDaddy',
    registrationDate: '2023-01-15',
    expirationDate: '2025-01-15',
    ownerName: 'John Doe',
    ownerEmail: 'john.doe@example.com',
    status: 'active',
    nameservers: 'ns1.example.com,ns2.example.com',
    imageUrl: 'https://placehold.co/100X100/png',
  };

  const defaultValues = mockFlag ? mockInitialValues : initialValues || {};

  // 当 initialValues 发生变化时，重置表单的初始值
  useEffect(() => {
    // console.log("Initial values:", defaultValues); // 打印初始值
    if (defaultValues && visible) {
      // 确保表单已经挂载后设置初始值
      form.setFieldsValue({
        ...defaultValues,
        registrationDate: defaultValues.registrationDate ? dayjs(defaultValues.registrationDate) : null,
        expirationDate: defaultValues.expirationDate ? dayjs(defaultValues.expirationDate) : null,
      });
    }
  }, [defaultValues, form, visible]); // 将 `visible` 添加到依赖数组，确保在表单可见时才设置字段

  return (
    <Modal
      title="添加/编辑域名"
      open={visible}
      onCancel={() => {
        onCancel(); // 调用取消处理函数
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk({
              ...values,
              // 将 dayjs 格式的日期转换为标准的字符串格式（如 YYYY-MM-DD）
              registrationDate: values.registrationDate ? values.registrationDate.format('YYYY-MM-DD') : null,
              expirationDate: values.expirationDate ? values.expirationDate.format('YYYY-MM-DD') : null,
            });
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      afterClose={() => {
        form.resetFields(); // 在 Modal 完全关闭后重置表单
      }}
    >
      <Form form={form} layout="vertical" name="addDomainForm">
        <Form.Item
          name="domainName"
          label="域名名称"
          rules={[{ required: true, message: '请输入域名名称' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="registrar"
          label="注册商"
          rules={[{ required: true, message: '请输入注册商' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="registrationDate"
          label="注册日期"
          rules={[{ required: true, message: '请输入注册日期' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="expirationDate"
          label="到期日期"
          rules={[{ required: true, message: '请输入到期日期' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="ownerName"
          label="持有者名称"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="ownerEmail"
          label="持有者邮箱"
          rules={[{ type: 'email', message: '请输入有效的邮箱地址' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="status"
          label="域名状态"
          rules={[{ required: true, message: '请输入域名状态' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="nameservers"
          label="域名服务器 (逗号分隔)"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="imageUrl"
          label="品牌图片网址"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DomainAddOrEditModal;