/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

// route/routeEntitlement.ts
import { RouteObject, useNavigate } from 'react-router-dom';
import EntitlementListPage from '../../logic/auto/entitlement/EntitlementListPage';
import EntitlementDetailPage from '../../logic/auto/entitlement/EntitlementDetailPage';
import { Button } from 'antd';

const entitlementRoutes: RouteObject[] = [
  { path: '/test/entitlement-list', element: <EntitlementListPage /> },
  { path: '/test/entitlement-detail/:id', element: <EntitlementDetailPage /> },
];

const entitlementAdminRoutes: RouteObject[] = [
  { path: '/test/admin/entitlement-list', element: <EntitlementListPage /> },
  { path: '/test/admin/entitlement-detail/:id', element: <EntitlementDetailPage /> },
];

// 测试入口组件
export const TestEntry: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button type="default" size="large" onClick={() => navigate('/test/entitlement-list')}>
      EntitlementList Test
    </Button>
  );
};

// 测试入口menu
export const menuData: {} = {
  key : "/test/admin/entitlement-list",
  path: "/test/admin/entitlement-list",
  name: "Entitlement管理"
}

export default { routes: entitlementRoutes, adminRoutes: entitlementAdminRoutes, TestEntry, menuData };
