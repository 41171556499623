import React, { Suspense } from 'react';
import { Button, Card, Row, Col, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { testEntries } from '../../route';

const { Title, Paragraph } = Typography;

const TestIndexPage: React.FC = () => {
  const navigate = useNavigate();

  // 页面跳转处理
  const goToPickerTest = () => {
    navigate('/test/picker-test');
  };

  const goToShouYinAdminTest = () => {
    navigate('/test/admin-login');
  };

  const goToImageTest = () => {
    navigate('/test/image-test');
  };

  const goToDrawerTest = () => {
    navigate('/test/drawer-test');
  };

  const goToCashierTest = () => {
    navigate('/test/cashier-test');
  };

  const goToCashierTest2 = () => {
    navigate('/test/cashier-test2');
  };

  return (
    <div style={{ padding: '40px', maxWidth: '800px', margin: '0 auto' }}>
      <Card style={{ textAlign: 'center', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <Title level={2}>测试页面入口</Title>
        <Paragraph>请选择你要测试的功能模块。</Paragraph>
        <Row gutter={[16, 16]} justify="center">
          {/* 静态按钮 */}
          <Col>
            <Button type="primary" size="large" onClick={goToShouYinAdminTest}>
              海内收银宝后台
            </Button>
          </Col>
          <Col>
            <Button type="default" size="large" onClick={goToPickerTest}>
              Picker 测试页面
            </Button>
          </Col>
          <Col>
            <Button type="default" size="large" onClick={goToImageTest}>
              Image 测试页面
            </Button>
          </Col>
          <Col>
            <Button type="default" size="large" onClick={goToDrawerTest}>
              Drawer 测试页面
            </Button>
          </Col>
          <Col>
            <Button type="default" size="large" onClick={goToCashierTest}>
              Cashier 测试页面1
            </Button>
          </Col>
          <Col>
            <Button type="default" size="large" onClick={goToCashierTest2}>
              Cashier 测试页面2
            </Button>
          </Col>

          {/* 动态生成的测试入口 */}
          <Suspense fallback={<div>Loading...</div>}>
            {testEntries.map((EntryComponent:any, index:any) => (
              <Col key={index}>
                <EntryComponent />
              </Col>
            ))}
          </Suspense>
        </Row>
      </Card>
    </div>
  );
};

export default TestIndexPage;