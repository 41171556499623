import React, { useState, useEffect } from "react";
import { Alert, Card, Button, Form, Input, Radio, Table, Modal, message } from "antd";
import axios from "axios";
import { createOrder, fetchPaymentData1 } from "../../logic/manual/api/apiCashier";
import { PayOrderResponse, PayOrderResult2 } from "../../logic/manual/Pay";
import CashierQrCodeModal2 from "../../component/CashierQrCodeModal2";
import { tableColumnsOrder } from "../../logic/manual/order/tableColumnsOrder";
import { paymentMethods } from "../../component/test/paymentMethods";
import { generateOrderNumber, generateRandomAmounts, generateRefundNo } from "../../utils/utils";
import './CashierTestPage2.css'; // 引入自定义样式
// 导入本地图片
import qrStaticCodeImage from "../../assets/cashier2/qrcode.png";
function CashierTestPage2() {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<any>("WX_NATIVE");
  const [selectedEntryPageType, setSelectedEntryPageType] = useState<any>(null);
  const [amountOption, setAmountOption] = useState<any>("0.01");
  const [amount, setAmount] = useState<any>(0.01);
  const [customAmount, setCustomAmount] = useState<any>("");
  const [orderNumber, setOrderNumber] = useState<any>(generateOrderNumber());
  const [paymentData, setPaymentData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [amountOptions, setAmountOptions] = useState<any[]>([]); // 初始金额选项为空数组
  const [qrCodeVisible, setQrCodeVisible] = useState<boolean>(false);
  const [staticQrImageModalVisible, setStaticQrImageModalVisible] = useState(false); // 控制图片的显示
  const [qrCodeUrl, setQrCodeUrl] = useState<string>("");
  const [form] = Form.useForm();

  useEffect(() => {
    refreshAmountOptions();
  }, []);

  useEffect(() => {
    fetchPaymentData();
  }, []);

  useEffect(() => {
    if (amountOption === "-1" && customAmount) {
      setAmount(parseFloat(customAmount));
    } else if (amountOption !== "-1") {
      setAmount(parseFloat(amountOption));
      setCustomAmount("");
    }
  }, [customAmount, amountOption]);

  const watchOrder = (orderNumber: string) => {
    let watchUrl : string = `wss://www.suantai.com/api/v1/test/anon/ws/payOrder/${orderNumber}/${new Date().getTime()}`

    console.log("Watch Server: ", watchUrl)

    // 创建 WebSocket 连接
    const ws = new WebSocket(watchUrl);

    // 监听 WebSocket 消息
    ws.onmessage = (event: any) => {
      const resDataJSON = JSON.parse(event.data);

      if (resDataJSON.state === 2) {
        message.success("支付成功");
        refreshOrderNumber(); // 刷新订单号
        fetchPaymentData(); // 获取支付数据
        setQrCodeVisible(false);
        ws.close(1000, "通知完成");
      } else if (resDataJSON.state === 3) {
        message.error("支付失败");
        setQrCodeVisible(false);
        ws.close(1000, "通知完成");
      } else if (resDataJSON.state === 5) {
        message.error("退款成功");
        setQrCodeVisible(false);
        ws.close(1000, "通知完成");
      }
    };

    // 监听 WebSocket 错误
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // 监听 WebSocket 关闭
    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // 返回一个关闭连接的方法
    return () => {
      ws.close();
    };
  };
  function selectPaymentMethod(method: any) {
    setSelectedPaymentMethod(method.code);

    if (method.code === "QR_CASHIER_STATIC") {
      setStaticQrImageModalVisible(true); // 显示图片
      return
    }

    if (method.entryPageType) {
      setSelectedEntryPageType(method.entryPageType);
    } else if (method.isStaticCode) {
      setSelectedEntryPageType('staticCode');
    } else {
      setSelectedEntryPageType(null);
    }
  }

  function handleAmountChange(e: any) {
    const value = e.target.value;
    setAmountOption(value);
    if (value === "-1") {
      setAmount("");
    } else {
      setAmount(parseFloat(value));
      setCustomAmount("");
    }
  }

  function refreshOrderNumber() {
    setOrderNumber(generateOrderNumber());
  }

  // 更新随机金额选项
  function refreshAmountOptions() {
    const randomAmounts = generateRandomAmounts();
    setAmountOptions(["0.01", ...randomAmounts, "-1"]); // 更新金额选项
  }

  async function handlePay() {
    if (!amount || amount <= 0) {
      message.error("请输入正确的金额，0-100000之间最多两位小数");
      return;
    }

    const reqData: any = {
      amount,
      wayCode: selectedPaymentMethod,
      mchOrderNo: orderNumber,
      subject: "支付接口演示",
    };
    if (selectedEntryPageType) {
      reqData.entryPageType = selectedEntryPageType;
    }

    if (selectedPaymentMethod.endsWith("_BAR")) {
      Modal.confirm({
        title: "条码支付",
        content: (
          <div>
            <p>请输入用户条码：</p>
            <Input id="authCodeInput" />
          </div>
        ),
        onOk: () => {
          const authCodeElement: any = document.getElementById(
            "authCodeInput"
          ) as any;
          const authCode = authCodeElement ? authCodeElement.value : "";
          if (!authCode) {
            message.error("请输入用户条码");
            return Promise.reject();
          }
          reqData.authCode = authCode;
          return makePayment(reqData);
        },
      });
    } else {
      makePayment(reqData);
    }
  }

  async function makePayment(reqData: any) {
    setLoading(true);
    try {
      watchOrder(orderNumber);
      const res: PayOrderResult2 = await createOrder(reqData);
      if (res.code === 0) {
        const data = res.data;
        if (data.payDataType === "form") {
          const div = document.createElement("div");
          div.innerHTML = data.payData;
          document.body.appendChild(div);
          (div.querySelector("form") as any).submit();
        } else if (data.payDataType === "codeImgUrl") {
          let imageUrl = "";

          try {
            // 尝试将字符串解析为JSON对象
            const jsonObject = JSON.parse(data.payData);

            // 判断是否为对象且是否有`qrUrl`属性
            if (typeof jsonObject === 'object' && jsonObject !== null && 'qrUrl' in jsonObject) {
              imageUrl = jsonObject.qrUrl;
            } else {
              // 不是对象或没有`qrUrl`属性
              imageUrl = data.payData;
            }
          } catch (e) {
            // 捕获解析JSON失败的情况，说明data.payData不是JSON对象
            imageUrl = data.payData;
          }

          setQrCodeUrl(imageUrl)
          setQrCodeVisible(true)
          refreshOrderNumber();
        } else if (data.payDataType === "payurl") {
          window.open(data.payData, "_blank");
        } else {
          message.info(JSON.stringify(data));
          refreshOrderNumber();
        }
      } else {
        message.error(res.msg);
        refreshOrderNumber();
      }
    } catch (error) {
      message.error("接口服务异常");
      refreshOrderNumber();
    } finally {
      setLoading(false);
    }
  }

  async function fetchPaymentData() {
    try {
      const res:PayOrderResponse = await fetchPaymentData1(1, 10);
      if (res.code === 0) {
        setPaymentData(res.data || []);
      } else {
        message.error(res.msg);
      }
    } catch (error) {
      message.error("接口服务异常");
    }
  }

  function handleRefund(record: any) {
    const mchRefundNo = generateRefundNo();
    Modal.confirm({
      title: `确认退款 ${record.amount / 100} 元？`,
      onOk: async () => {
        try {
          const response = await axios.post("/api/pay/refund", {
            mchOrderNo: record.orderNo,
            mchRefundNo: mchRefundNo,
          });
          const res = response.data;
          if (res.code === 0) {
            if (res.data.state === 2) {
              message.success("退款成功");
              fetchPaymentData();
            }
          } else {
            message.error(res.msg);
          }
        } catch (error) {
          message.error("接口服务异常");
        }
      },
    });
  }

    // 扩展表格列，添加操作按钮
    const extendedColumns = [
      ...tableColumnsOrder,
      {
        title: "操作",
        key: "action",
        render: (text: any, record: any) => (
          <Button
            type="link"
            disabled={!record.userId || record.status !== 2}
            onClick={() => handleRefund(record)}
          >
            退款
          </Button>
        ),
      },
    ];

  return (
    <div style={{ backgroundColor: "#f5f5f7" }}>
      <div className="page paydemo">
        <div className="blog-container" id="container">
          <div>
            <Alert
              message={
                <span>
                  本页面支付测试对接
                  <a href="https://www.berapay.cn" target="_blank" rel="noopener noreferrer">小熊支付</a>
                  （使用
                  <a href="/product/berapay4plus.html">BeraPay</a>
                  搭建），下单测试需谨慎，付款失误可退回！！！
                </span>
              }
              type="info"
              showIcon
              style={{ marginBottom: "20px" }}
            />
          </div>

          <Card className="paydemo-type-content">
            {paymentMethods.map((category: any) => (
              <div key={category.category} style={{ marginBottom: "20px" }}>
                <h3>{category.category}</h3>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {category.methods.map((method: any) => (
                    <Card
                      key={method.code + (method.entryPageType || "")}
                      className={`paydemo-type ${
                        method.code === selectedPaymentMethod ? "paydemo-type-selected" : ""
                      }`}
                      hoverable
                      onClick={() => selectPaymentMethod(method)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={method.icon}
                          alt={method.name}
                          className="paydemo-type-img"
                        />
                        <Card.Meta
                          title={method.name}
                          style={{ textAlign: "left" }} // 名称与图标左对齐
                        />
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            ))}
          </Card>
          <Card className="paydemo-type-content">
            <div>
              <h3>支付信息</h3>
              <Form form={form} onFinish={handlePay}>
                <Form.Item label="订单编号">
                  <span>{orderNumber}</span>
                  <Button
                    size="small"
                    onClick={refreshOrderNumber}
                    style={{ marginLeft: "10px" }}
                  >
                    刷新订单号
                  </Button>
                </Form.Item>
                <Form.Item label="商品名称">
                  <span>支付接口演示</span>
                </Form.Item>
                <Form.Item label="支付金额(元)">
                  <Form.Item name="amountOption" noStyle>
                    <Radio.Group
                      onChange={handleAmountChange}
                      value={amountOption}
                    >
                      {amountOptions.map((option: any) => (
                        <Radio key={option} value={option}>
                          {option === "-1" ? "自定义金额" : option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  {amountOption === "-1" && (
                    <Form.Item name="customAmount" noStyle>
                      <Input
                        style={{ width: "120px", marginLeft: "10px" }}
                        placeholder="请输入金额"
                        onChange={(e) => setCustomAmount(e.target.value)}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
                <div style={{ textAlign: "right" }}>
                  <span
                    style={{
                      color: "#FD482C",
                      fontSize: "18px",
                      paddingRight: "10px",
                    }}
                  >
                    ￥{amount}
                  </span>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    立即支付
                  </Button>
                </div>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: "rgb(68, 181, 73)", fontSize: "12px" }}>
                    登录后支付，可发起退款
                  </span>
                </div>
              </Form>
            </div>
          </Card>
          <Card className="paydemo-type-content">
            <div>
              <h3>最新支付</h3>
              <Button
                type="primary"
                onClick={fetchPaymentData}
                style={{ marginBottom: "10px" }}
              >
                刷新
              </Button>
              <Table
                dataSource={paymentData}
                columns={extendedColumns}
                rowKey="orderNo"
                className="layui-table-view"
              />
            </div>
          </Card>

          <CashierQrCodeModal2
            visible={qrCodeVisible}
            qrCodeUrl={qrCodeUrl}
            imageUrl={qrCodeUrl}
            onOk={() => {
              setQrCodeVisible(false);
              refreshOrderNumber();
            }}
            onCancel={() => {
              setQrCodeVisible(false); // 关闭模态框
              refreshOrderNumber();
            }}
          />
          <Modal
            open={staticQrImageModalVisible}
            onCancel={() => setStaticQrImageModalVisible(false)}
            footer={null}
            centered
            style={{ background: 'transparent', boxShadow: 'none' }} // 移除白色背景和阴影
            styles={{
              content: { background: 'transparent', boxShadow: 'none' }, // 设置模态框内容区域的样式
              body: { backgroundColor: 'transparent', padding: 0 }, // 设置模态框内部的内容样式
              mask: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } // 设置遮罩层的样式
            }}
          >
            <img
              src={qrStaticCodeImage}
              alt="静态码支付二维码"
              style={{ width: "100%" }} // 确保图片占满模态框
            />
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default CashierTestPage2;