/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // 导入 useLocation 钩子
import { Button, Descriptions, message, Spin } from 'antd';
import { fetchUserAuthDetail } from './apiUserAuth'; // 假设你有获取UserAuth详情的 API
import type { UserAuth } from './UserAuth';

const UserAuthDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // 从 URL 中获取 ID
  const navigate = useNavigate(); // 使用 useNavigate
  const location = useLocation(); // 获取当前路径信息
  const [data, setData] = useState<UserAuth | null>(null); // 存储详情
  const [loading, setLoading] = useState(false); // 加载状态

  // 判断是否为管理员路径
  const isAdminPath = location.pathname.includes('/admin');

  // 获取详情
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchUserAuthDetail(Number(id)); // 调用封装的 API 获取详情
        if (response.data) {
          setData(response.data);
        } else {
          message.error('未找到相关信息');
        }
      } catch (error) {
        console.error('Error fetching details:', error);
        message.error('无法获取详情');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // 返回列表
  const handleBack = () => {
    const listPath = isAdminPath ? '/test/admin/userauth-list' : '/test/userauth-list';
    navigate(listPath); // 根据当前路径跳转到不同的列表页
  };
  // 如果正在加载，显示 Spin 组件
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin tip="" size="large">
          <div style={{ height: '100px' }}></div> {/* 子组件内容作为 Spin 的嵌套内容 */}
        </Spin>
      </div>
    );
  }

  // 如果未找到信息，显示提示
  if (!data) {
    return <p>未找到详情信息</p>;
  }

  // 正常渲染详情
  return (
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-bold mb-4">UserAuth - 详情</h2>
      <Descriptions bordered>
        <Descriptions.Item label="认证ID">
            {/*
              默认显示字段值
            */}
            { data.authId }
        </Descriptions.Item>
        <Descriptions.Item label="用户ID">
            {/*
              默认显示字段值
            */}
            { data.userId }
        </Descriptions.Item>
        <Descriptions.Item label="登录类型">
            {/*
              如果存在 value_map，则根据映射关系显示相应的值
            */}
            {
              (() => {
                switch (data.identityType) {
                  case 1:
                    return '登录账号';
                  case 2:
                    return '手机号';
                  case 3:
                    return '邮箱';
                  case 10:
                    return '微信';
                  case 11:
                    return 'QQ';
                  case 12:
                    return '支付宝';
                  case 13:
                    return '微博';
                  default:
                    return '未知';
                }
              })()
            }
        </Descriptions.Item>
        <Descriptions.Item label="认证标识（用户名 ｜open_id）">
            {/*
              默认显示字段值
            */}
            { data.identifier }
        </Descriptions.Item>
        <Descriptions.Item label="密码凭据">
            {/*
              默认显示字段值
            */}
            { data.credential }
        </Descriptions.Item>
        <Descriptions.Item label="SALT">
            {/*
              默认显示字段值
            */}
            { data.salt }
        </Descriptions.Item>
        <Descriptions.Item label="所属系统">
            {/*
              默认显示字段值
            */}
            { data.sysType }
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.createdAt).toLocaleString() }
        </Descriptions.Item>
        <Descriptions.Item label="更新时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.updatedAt).toLocaleString() }
        </Descriptions.Item>
      </Descriptions>
      <Button type="primary" onClick={handleBack} className="mt-4">
        返回列表
      </Button>
    </div>
  );
};

export default UserAuthDetailPage;
