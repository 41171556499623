import Mock from 'mockjs';

Mock.Random.extend({
  // 自定义 Mock.js 扩展方法，生成 ISO 格式的时间字符串，带 .000Z 后缀
  bera_iso_datetime() {
    return Mock.mock('@datetime').replace(' ', 'T') + '.000Z';
  },
  // 自定义 Mock.js 扩展方法，生成随机手机号
  bera_telephone() {
    return Mock.mock(/^1[34578]\d{9}$/);
  },
});

// 导出 Mock，以便其他文件中使用扩展功能
export default Mock;

// 生成 Mock 数据模板的公共函数
export const generateMockTemplate = (rulesMap: Record<string, any>) => {
    const template: any = {};
    Object.keys(rulesMap).forEach(key => {
      const ruleConfig = rulesMap[key];

      if (typeof ruleConfig === 'object' && ruleConfig.rule) {
        // 处理复杂规则（带初始值或规则符号）
        const { rule, initial } = ruleConfig;
        // 动态生成符合 Mock.js 规则格式的键名和规则
        template[`${key}|${rule}`] = initial !== undefined ? initial : Mock.mock(rule);
      } else {
        // 处理简单规则（字符串或普通值）
        template[key] = ruleConfig;
      }
    });
    return template;
  };
