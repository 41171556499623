import { useState, useEffect, useRef } from 'react';
import { Spin, Alert, Button, Input, Switch, Radio, InputNumber, Affix, message } from 'antd';
import QrCode from '../../component/QrCode';
import CashierQrCodeModal from '../../component/CashierQrCodeModal'; // 二维码模态框组件
import BarcodeModal from '../../component/BarcodeModal'; // 条码支付模态框组件
import aliQr from './imgs/ali/ali_qr.svg';
import aliBar from './imgs/ali/ali_bar.svg';
import aliPc from './imgs/ali/ali_pc.svg';
import aliWap from './imgs/ali/ali_wap.svg';
import wxNative from './imgs/wechat/wx_native.svg';
import wxBar from './imgs/wechat/wx_bar.svg';
import wxH5 from './imgs/wechat/wx_h5.svg';
import qrCashier from './imgs/aggregate/qr_cashier.svg';
import autoBar from './imgs/aggregate/auto_bar.svg';
import './CashierTestPage.css'; // 导入样式

// 导入 API 请求函数
import { aggregateBarCodePay, createAggregatePayUrl, findStatusByBizOrderNo, getUniCashierUrl, simplePayCashier } from '../../logic/manual/api/apiCashier';

// 定义支付信息的类型
type PayInfo = { channel: string; method: string };

const CashierTestPage = () => {
  const [loading, setLoading] = useState(false);
  const [bizOrderNo, setBizOrderNo] = useState('');
  const [title, setTitle] = useState('支付接口演示');
  const [allocation, setAllocation] = useState(false);
  const [wxH5Url, setWxH5Url] = useState('初始化中...');
  const [aliH5Url, setAliH5Url] = useState('初始化中...');
  const [h5cashierUrl, setH5cashierUrl] = useState(''); // 用于 H5 收银台的 URL
  const [currentActive, setCurrentActive] = useState<PayInfo | null>(null);
  const [payMoneyValue, setPayMoneyValue] = useState<number | null>(0.01);
  const [totalMoney, setTotalMoney] = useState<number>(0.01);
  const [payMoneyShow, setPayMoneyShow] = useState(false);
  const [wxHover, setWxHover] = useState(false);
  const [aliHover, setAliHover] = useState(false);

  // 控制模态框的显示与隐藏
  const [isQrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const [qrModalTitle, setQrModalTitle] = useState('扫码支付');
  const [qrModalUrl, setQrModalUrl] = useState('');
  const [isBarcodeModalVisible, setBarcodeModalVisible] = useState(false);
  const [barcodeLoading, setBarcodeLoading] = useState(false);
  const [barcodeTopTitle, setBarcodeTopTitle] = useState('条码支付');

  // 使用 useRef 保存 interval 引用
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // 支付列表数据
  const AliPayList = [
    { img: aliQr, title: '扫码支付', payInfo: { channel: 'ALI', method: 'QRCODE' } },
    { img: aliBar, title: '条码支付', payInfo: { channel: 'ALI', method: 'BARCODE' } },
    { img: aliPc, title: 'PC支付', payInfo: { channel: 'ALI', method: 'WEB' } },
    { img: aliWap, title: 'WAP支付', payInfo: { channel: 'ALI', method: 'WAP' } },
  ];

  const WxPayList = [
    { img: wxNative, title: '扫码支付', payInfo: { channel: 'WECHAT', method: 'QRCODE' } },
    { img: wxBar, title: '条码支付', payInfo: { channel: 'WECHAT', method: 'BARCODE' } },
    { img: wxH5, title: 'WAP支付', payInfo: { channel: 'WECHAT', method: 'WAP' } },
  ];

  const UniPayList = [
    { img: wxNative, title: '扫码支付', payInfo: { channel: 'UNION_PAY', method: 'QRCODE' } },
    { img: wxBar, title: '条码支付', payInfo: { channel: 'UNION_PAY', method: 'BARCODE' } },
    { img: wxH5, title: 'WAP支付', payInfo: { channel: 'UNION_PAY', method: 'WAP' } },
    { img: aliPc, title: 'WEB支付', payInfo: { channel: 'UNION_PAY', method: 'WEB' } },
  ];

  const aggregationPayList = [
    { img: qrCashier, title: '扫码支付', payInfo: { channel: 'AGGREGATION', method: 'QRCODE' } },
    { img: autoBar, title: '条码支付', payInfo: { channel: 'AGGREGATION', method: 'BARCODE' } },
  ];

  // 初始化业务单号和H5支付链接
  useEffect(() => {
    genBizOrderNo();
    initH5CashierUrl();
  }, []);

  // 生成业务单号
  const genBizOrderNo = () => {
    setBizOrderNo('P' + Date.now());
  };

  // 初始化 H5 支付链接
  const initH5CashierUrl = async () => {
    // const res = await getUniCashierUrl();
    // setWxH5Url(res); // 直接设置 res
    // setAliH5Url(res); // 直接设置 res
    // setH5cashierUrl(res); // 为 H5 收银台设置 URL
  };

  // 支付金额变化处理
  const payMoneyValueChange = (e: any) => {
    setPayMoneyValue(e.target.value);
    setPayMoneyShow(e.target.value === undefined);
  };

  const handleTotalMoneyChange = (value: number | null) => {
    setTotalMoney(value || 0.01);
  };

  // 开始支付
  const pay = async () => {
    if (!currentActive) return;

    // 普通扫码支付
    if (currentActive.method === 'QRCODE') {
      const param = {
        title,
        bizOrderNo,
        allocation,
        channel: currentActive.channel,
        amount: totalMoney,
        method: currentActive.method,
      };
      const res = await simplePayCashier(param);
      setQrModalUrl(res.payBody); // 修改为直接访问 res
      setQrModalTitle('请使用支付宝或微信扫码支付');
      setQrCodeModalVisible(true);
      checkPayStatus();
    }

    // 条码支付
    if (currentActive.method === 'BARCODE') {
      setBarcodeTopTitle('条码支付');
      setBarcodeLoading(false);
      setBarcodeModalVisible(true);
    }
  };

  // 聚合支付的二维码支付
  const aggregationQrPay = async () => {
    const param = {
      bizOrderNo,
      allocation,
      amount: totalMoney,
      title,
    };
    const res = await createAggregatePayUrl(param);
    setQrModalUrl(res.payBody); // 修改为直接访问 res
    setQrModalTitle('请使用支付宝或微信扫码支付');
    setQrCodeModalVisible(true);
    checkPayStatus();
  };

  // H5 收银台
  const h5cashier = () => {
    setQrModalUrl(h5cashierUrl);
    setQrModalTitle('请使用浏览器、支付宝、微信等软件扫码体验');
    setQrCodeModalVisible(true);
  };

  // 条码支付的具体处理
  const barPay = async (authCode: string) => {
    const param = {
      title,
      bizOrderNo,
      allocation,
      amount: totalMoney,
      channel: currentActive?.channel,
      method: currentActive?.method,
      authCode,
    };
    const res = await aggregateBarCodePay(param);
    message.success('支付成功');
    setBarcodeModalVisible(false);
  };

  // 定时轮询支付状态
  const checkPayStatus = () => {
    intervalRef.current = setInterval(async () => {
      const res = await findStatusByBizOrderNo(bizOrderNo);
      if (res) { // 直接使用 res，而不是 res.data
        message.success('支付成功');
        clearInterval(intervalRef.current!);
        setQrCodeModalVisible(false);
      }
    }, 3000); // 每隔 3 秒检查支付状态
  };

  // 组件销毁时清除轮询
  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  // 支付方式选择
  const handleActive = (payInfo: PayInfo) => {
    setCurrentActive(payInfo);
  };

  return (
    <div style={{ backgroundColor: '#f5f5f7' }}>
      <div className="page paydemo">
        <div className="blog-container" id="container">
          <Spin spinning={loading}>
            <div className="content" style={{ paddingTop: '20px' }}>
              <div style={{ width: '100%' }}>
                <Alert
                  message="本收银台是基于小熊支付网关搭建的演示模块，支付后可以通过管理端进行退款。"
                  type="warning"
                  showIcon
                  style={{ marginBottom: '20px', padding: '15px' }}
                />

                <div className="paydemo-type-content">
                  <div className="paydemo-type-name">微信支付</div>
                  <div className="paydemo-type-body">
                    {WxPayList.map((item, index) => (
                      <div key={index} onClick={() => handleActive(item.payInfo)}>
                        <div className={currentActive?.channel === item.payInfo.channel && currentActive.method === item.payInfo.method ? 'colorChange' : 'paydemoType'}>
                          <img src={item.img} className="paydemo-type-img" alt={item.title} />
                          <span className="color-change">{item.title}</span>
                        </div>
                      </div>
                    ))}

                    <div style={{ position: 'relative' }}>
                      <div className="paydemo-type-h5" onMouseEnter={() => setWxHover(true)} onMouseLeave={() => setWxHover(false)}>
                        <img src={wxH5} className="paydemo-type-img" alt="微信" />
                        <span className="color-change">公众号</span>
                      </div>
                      {wxHover && (
                        <div className="paydemo-type-h5 codeImg_wx_h5">
                          <QrCode value={wxH5Url} size={150} level="H" />
                          <span>使用微信扫码体验</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="paydemo-type-name">支付宝支付</div>
                  <div className="paydemo-type-body">
                    {AliPayList.map((item, index) => (
                      <div key={index} onClick={() => handleActive(item.payInfo)}>
                        <div className={currentActive?.channel === item.payInfo.channel && currentActive.method === item.payInfo.method ? 'colorChange' : 'paydemoType'}>
                          <img src={item.img} className="paydemo-type-img" alt={item.title} />
                          <span className="color-change">{item.title}</span>
                        </div>
                      </div>
                    ))}

                    <div style={{ position: 'relative' }}>
                      <div className="paydemo-type-h5" onMouseEnter={() => setAliHover(true)} onMouseLeave={() => setAliHover(false)}>
                        <img src={aliWap} className="paydemo-type-img" alt="支付宝" />
                        <span className="color-change">h5支付</span>
                      </div>
                      {aliHover && (
                        <div className="paydemo-type-h5 codeImg_wx_h5">
                          <QrCode value={aliH5Url} size={150} level="H" />
                          <span>使用支付宝扫码体验</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="paydemo-type-name">云闪付支付（沙箱环境）</div>
                  <div className="paydemo-type-body">
                    {UniPayList.map((item, index) => (
                      <div key={index} onClick={() => handleActive(item.payInfo)}>
                        <div className={currentActive?.channel === item.payInfo.channel && currentActive.method === item.payInfo.method ? 'colorChange' : 'paydemoType'}>
                          <img src={item.img} className="paydemo-type-img" alt={item.title} />
                          <span className="color-change">{item.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="paydemo-type-name">聚合支付</div>
                  <div className="paydemo-type-body">
                    {aggregationPayList.map((item, index) => (
                      <div key={index} onClick={() => handleActive(item.payInfo)}>
                        <div className={currentActive?.channel === item.payInfo.channel && currentActive.method === item.payInfo.method ? 'colorChange' : 'paydemoType'}>
                          <img src={item.img} className="paydemo-type-img" alt={item.title} />
                          <span className="color-change">{item.title}</span>
                        </div>
                      </div>
                    ))}
                    <div className="paydemo-type-h5" onClick={h5cashier}>
                      <img src={qrCashier} className="paydemo-type-img" alt="H5收银台" style={{ marginLeft: '8px' }} />
                      <span>H5收银台</span>
                    </div>
                  </div>
                </div>

                <div className="paydemo-type-content">
                  <div className="paydemo-type-name">支付信息</div>
                  <div className="paydemo-form-item" style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>业务单号：</label>
                    <Input value={bizOrderNo} onChange={(e) => setBizOrderNo(e.target.value)} style={{ width: '200px' }} />
                    <Button type="primary" onClick={genBizOrderNo} style={{ marginLeft: '10px' }}>
                      生成
                    </Button>
                  </div>
                  <div className="paydemo-form-item">
                    <label style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>支付标题：</label>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} style={{ width: '200px' }} />
                  </div>
                  <div className="paydemo-form-item">
                    <label>是否分账：</label>
                    <Switch checked={allocation} onChange={setAllocation} checkedChildren="启用" unCheckedChildren="停用" />
                  </div>
                  <div className="paydemo-form-item">
                    <label>支付金额(元)：</label>
                    <Radio.Group value={payMoneyValue} onChange={payMoneyValueChange}>
                      {[0.01, 0.02, 0.03, 0.1, 1.0].map((amount, index) => (
                        <Radio key={index} value={amount}>
                          {amount}
                        </Radio>
                      ))}
                      <Radio value={undefined}>自定义</Radio>
                    </Radio.Group>
                    {payMoneyShow && (
                      <InputNumber
                        style={{ width: 120 }}
                        min={0.01}
                        max={100}
                        precision={2}
                        value={totalMoney || 0.01} // 确保 value 不为 null
                        onChange={handleTotalMoneyChange}
                      />
                    )}
                  </div>

                  <div style={{ marginTop: 20, textAlign: 'right' }}>
                    <Affix offsetBottom={20}>
                      <div>
                        <span style={{ color: '#fd482c', fontSize: 18, paddingRight: 10 }}>¥ {totalMoney}</span>
                        <Button type="primary" disabled={!currentActive?.method || !totalMoney} onClick={pay}>
                          立即支付
                        </Button>
                      </div>
                    </Affix>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>

      {/* CashierQrCodeModal for QR code payments */}
      <CashierQrCodeModal
        visible={isQrCodeModalVisible}
        qrUrl={qrModalUrl}
        bottomTitle={qrModalTitle}
        onCancel={() => setQrCodeModalVisible(false)}
      />

      {/* BarcodeModal for barcode payments */}
      <BarcodeModal
        visible={isBarcodeModalVisible}
        loading={barcodeLoading}
        topTitle={barcodeTopTitle}
        onOk={(authCode) => barPay(authCode)} // 处理条码支付
        onCancel={() => setBarcodeModalVisible(false)}
      />
    </div>
  );
};

export default CashierTestPage;