/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import type { ColumnsType } from 'antd/es/table';
import { Article } from './Article';

// 生成 Article 表格列配置
export const tableColumnsArticle: ColumnsType<Article> = [
  {
    title: '文章ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '门店ID',
    dataIndex: 'storeId',
    key: 'storeId',
  },
  {
    title: '商户ID',
    dataIndex: 'merchantId',
    key: 'merchantId',
  },
  {
    title: '链接地址',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: '图片地址',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '点击次数',
    dataIndex: 'click',
    key: 'click',
  },
  {
    title: '最后操作人',
    dataIndex: 'operator',
    key: 'operator',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  }
];
