/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import Mock, { generateMockTemplate } from '../../manual/mock';

// 定义规则映射（配置文件）
const rulesMap: any = {

  id: '@increment', // 文章ID
  storeId: '@natural(1000, 9000)', // 门店ID
  merchantId: '@natural(1000, 9000)', // 商户ID
  title: '@paragraph(1, 3)', // 文章标题
  brief: '@pick(["Fiction", "Non-Fiction", "Science", "Biography", "Fantasy", "History", "Children", "Self-Help"])', // 文章简介
  url: '@natural(1000000000000, 9999999999999)', // 链接地址
  image: '@pick(["Bestseller", "New Arrival", "Classic", "Award Winning", "Recommended", "Series"])', // 图片地址
  description: '@image("200x200", "#FFCC33", "#FFF", "Book")', // 描述
  click: '@natural(1000, 9000)', // 点击次数
  createdAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 创建时间
  updatedAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 更新时间
  operator: '@cname', // 最后操作人
  sort: '@natural(1, 100)', // 排序
  status: '@pick(["A", "N", "D"])', // 状态
};

// 生成 mock 数据模板
const articleTemplate: any = generateMockTemplate(rulesMap);

// 生成 mock 数据
let mockArticleData = Mock.mock({
  'articles|100': [articleTemplate] // 生成100条数据
}).articles;

// 获取Article列表（模拟的分页）
export const getArticleMockData = (page: number, pageSize: number): Promise<any> => {
  const start = (page - 1) * pageSize;
  const records = mockArticleData.slice(start, start + pageSize);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        errorCode: 0,
        message: 'Request processed successfully',
        data: {
          records,
          total: mockArticleData.length,
          size: pageSize,
          current: page,
          pages: Math.ceil(mockArticleData.length / pageSize),
        },
      });
    }, 50);
  });
};

// 模拟添加Article
export const mockAddArticle = (newArticle: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockArticleData.push({
      ...newArticle,
      id: mockArticleData.length + 1,
      createdAt: now,
      updatedAt: now
    });
    setTimeout(() => resolve({ errorCode: 0, message: 'Article added successfully' }), 50);
  });
};

// 模拟编辑Article
export const mockEditArticle = (id: number, updatedArticle: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockArticleData = mockArticleData.map((item:any) =>
      (item.id === id ? { ...item, ...updatedArticle, updatedAt: now } : item)
    );
    setTimeout(() => resolve({ errorCode: 0, message: 'Article updated successfully' }), 50);
  });
};

// 模拟删除Article
export const mockDeleteArticle = (id: number): Promise<any> => {
  return new Promise((resolve) => {
    mockArticleData = mockArticleData.filter((item:any) => item.id !== id);
    setTimeout(() => resolve({ errorCode: 0, message: 'Article deleted successfully' }), 50);
  });
};

// 获取Article详情（模拟）
export const mockFetchArticleDetail = (id: number): Promise<any> => {
  const item = mockArticleData.find((item:any) => item.id === id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (item) {
        resolve({ data: item }); // 返回找到的Article
      } else {
        reject(new Error('Article not found')); // 未找到Article时返回错误
      }
    }, 50); // 模拟延迟50ms
  });
};
