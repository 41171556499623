import React from 'react';
import { Modal, Form, DatePicker, TimePicker, Input, InputNumber, Select, Cascader, message } from 'antd';
import dayjs from 'dayjs';

const { RangePicker, MonthPicker } = DatePicker;
const { Option } = Select;

interface TestPickerModalProps {
  visible: boolean;
  initialData: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}


const cascaderOptions = [
{
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
    {
        value: 'hangzhou',
        label: 'Hangzhou',
    },
    ],
},
{
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
    {
        value: 'nanjing',
        label: 'Nanjing',
    },
    ],
},
];

const TestPickerModal: React.FC<TestPickerModalProps> = ({
  visible,
  initialData,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const formattedValues = {
          testDate: values.testDate ? values.testDate.format('YYYY-MM-DD') : null,
          testTime: values.testTime ? values.testTime.format('HH:mm:ss') : null,
          testRange: values.testRange ? [values.testRange[0].format('YYYY-MM-DD'), values.testRange[1].format('YYYY-MM-DD')] : null,
          testMonth: values.testMonth ? values.testMonth.format('YYYY-MM') : null,
          testColor: values.testColor,
          testNumber: values.testNumber,
          testSelect: values.testSelect,
          testCascader: values.testCascader,
        };

        onSubmit(formattedValues);
      })
      .catch((info) => {
        message.error('表单校验失败');
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="测试各种 Picker"
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      afterClose={() => form.resetFields()}
    >
      <Form form={form} layout="vertical" initialValues={initialData}>
        <Form.Item
          name="testDate"
          label="选择日期"
          rules={[{ required: true, message: '请选择日期' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="testTime"
          label="选择时间"
          rules={[{ required: true, message: '请选择时间' }]}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="testRange"
          label="选择日期范围"
          rules={[{ required: true, message: '请选择日期范围' }]}
        >
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="testMonth"
          label="选择月份"
          rules={[{ required: true, message: '请选择月份' }]}
        >
          <MonthPicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="testColor"
          label="选择颜色"
          rules={[{ required: true, message: '请选择颜色' }]}
        >
          <Input type="color" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="testNumber"
          label="选择数字"
          rules={[{ required: true, message: '请选择一个数字' }]}
        >
          <InputNumber min={1} max={100} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="testSelect"
          label="选择一个选项"
          rules={[{ required: true, message: '请选择一个选项' }]}
        >
          <Select style={{ width: '100%' }}>
            <Option value="option1">选项 1</Option>
            <Option value="option2">选项 2</Option>
            <Option value="option3">选项 3</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="testCascader"
          label="选择级联"
          rules={[{ required: true, message: '请选择级联选项' }]}
        >
          <Cascader options={cascaderOptions} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TestPickerModal;