/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, DatePicker, Checkbox, Radio } from 'antd';

interface EntitlementAddOrEditModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues?: any; // 支持传递初始值
}

const EntitlementAddOrEditModal: React.FC<EntitlementAddOrEditModalProps> = ({ visible, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm();

  // 当 initialValues 发生变化时，重置表单的初始值，确保所有字段都设置初始值
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  // 表单提交时，将未显示在表单中的字段也透传到服务器
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const combinedValues = { ...initialValues, ...values }; // 合并表单数据和初始数据
        onOk(combinedValues); // 将完整数据传递给父组件
        form.resetFields(); // 重置表单
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="添加/编辑权限表"
      open={visible}
      onCancel={() => {
        form.resetFields(); // 重置表单
        onCancel(); // 调用取消处理函数
      }}
      onOk={handleOk} // 在表单提交时调用 handleOk 函数
    >
      <Form form={form} layout="vertical" name="addOrEditEntitlementForm" initialValues={initialValues}>
        <Form.Item
          name="entName"
          label="权限名称"
          rules={[
            { required: true, message: '请输入权限名称' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="menuIcon"
          label="菜单图标"
          rules={[
            { required: false, message: '请输入菜单图标' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="menuUri"
          label="菜单uri/路由地址"
          rules={[
            { required: false, message: '请输入菜单uri/路由地址' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="componentName"
          label="组件名称"
          rules={[
            { required: false, message: '请输入组件名称' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="entType"
          label="权限类型"
          rules={[
            { required: true, message: '请输入权限类型' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="quickJump"
          label="快速开始菜单"
          rules={[
            { required: true, message: '请输入快速开始菜单' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          name="state"
          label="状态"
          rules={[
            { required: true, message: '请输入状态' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          name="pid"
          label="父权限ID"
          rules={[
            { required: true, message: '请输入父权限ID' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="entSort"
          label="排序"
          rules={[
            { required: true, message: '请输入排序' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          name="sysType"
          label="所属系统"
          rules={[
            { required: true, message: '请输入所属系统' }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EntitlementAddOrEditModal;
