/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // 导入 useLocation 钩子
import { Button, Descriptions, message, Spin } from 'antd';
import { fetchConfigDetail } from './apiConfig'; // 假设你有获取Config详情的 API
import type { Config } from './Config';

const ConfigDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // 从 URL 中获取 ID
  const navigate = useNavigate(); // 使用 useNavigate
  const location = useLocation(); // 获取当前路径信息
  const [data, setData] = useState<Config | null>(null); // 存储详情
  const [loading, setLoading] = useState(false); // 加载状态

  // 判断是否为管理员路径
  const isAdminPath = location.pathname.includes('/admin');

  // 获取详情
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (!id!) {
          message.error('ID 不能为空');
          return;
        }
        const response = await fetchConfigDetail(id); // 调用封装的 API 获取详情
        if (response.data) {
          setData(response.data);
        } else {
          message.error('未找到相关信息');
        }
      } catch (error) {
        console.error('Error fetching details:', error);
        message.error('无法获取详情');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // 返回列表
  const handleBack = () => {
    const listPath = isAdminPath ? '/test/admin/config-list' : '/test/config-list';
    navigate(listPath); // 根据当前路径跳转到不同的列表页
  };
  // 如果正在加载，显示 Spin 组件
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin tip="" size="large">
          <div style={{ height: '100px' }}></div> {/* 子组件内容作为 Spin 的嵌套内容 */}
        </Spin>
      </div>
    );
  }

  // 如果未找到信息，显示提示
  if (!data) {
    return <p>未找到详情信息</p>;
  }

  // 正常渲染详情
  return (
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-bold mb-4">Config - 详情</h2>
      <Descriptions bordered>
        <Descriptions.Item label="配置KEY">
            {/*
              默认显示字段值
            */}
            { data.configKey }
        </Descriptions.Item>
        <Descriptions.Item label="配置名称">
            {/*
              默认显示字段值
            */}
            { data.configName }
        </Descriptions.Item>
        <Descriptions.Item label="描述信息">
            {/*
              默认显示字段值
            */}
            { data.configDesc }
        </Descriptions.Item>
        <Descriptions.Item label="分组KEY">
            {/*
              默认显示字段值
            */}
            { data.groupKey }
        </Descriptions.Item>
        <Descriptions.Item label="分组名称">
            {/*
              默认显示字段值
            */}
            { data.groupName }
        </Descriptions.Item>
        <Descriptions.Item label="配置内容项">
            {/*
              默认显示字段值
            */}
            { data.configVal }
        </Descriptions.Item>
        <Descriptions.Item label="配置类型">
            {/*
              默认显示字段值
            */}
            { data.type }
        </Descriptions.Item>
        <Descriptions.Item label="显示顺序">
            {/*
              默认显示字段值
            */}
            { data.sortNum }
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.createdAt).toLocaleString() }
        </Descriptions.Item>
        <Descriptions.Item label="更新时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.updatedAt).toLocaleString() }
        </Descriptions.Item>
      </Descriptions>
      <Button type="primary" onClick={handleBack} className="mt-4">
        返回列表
      </Button>
    </div>
  );
};

export default ConfigDetailPage;
