import { RouteObject } from 'react-router-dom';

// 定义 MenuData 类型，用于存储 TestMenu 的数据结构
interface MenuData {
  key: string;
  path: string;
  name: string;
}

// 自动导入 routes 目录下所有的 .tsx 文件（除 index.tsx 和 entry.tsx 以外）
const routeModules: any = require.context('.', true, /\.tsx$/);
const routes: RouteObject[] = [];
const adminRoutes: RouteObject[] = [];
const testEntries: React.FC[] = []; // 存储 TestEntry 组件
const testMenus: MenuData[] = []; // 存储 TestMenu 数据结构

routeModules.keys().forEach((modulePath: string) => {
  if (modulePath !== './index.tsx' && modulePath !== './entry.tsx') {
    const module = routeModules(modulePath).default;

    // 判断 module 是否是数组类型（RouteObject[]）还是单个 RouteObject
    if (module && Array.isArray(module.routes)) {
      routes.push(...module.routes); // 如果模块是一个路由数组，则合并到 routes 中
    }

    // 检查模块中是否有 adminRoutes 属性，并合并到 adminRoutes 数组中
    if (module && Array.isArray(module.adminRoutes)) {
      adminRoutes.push(...module.adminRoutes);
    }

    // 如果模块中有 TestEntry 组件，则将其添加到 testEntries 中
    if (module && module.TestEntry) {
      testEntries.push(module.TestEntry);
    }

    // 如果模块中有 menuData 属性，则将其添加到 testMenus 中
    if (module && module.menuData) {
      testMenus.push(module.menuData);
    }
  }
});

// 导出收集到的数据
export { testEntries, testMenus, adminRoutes };
export default routes;
