/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, DatePicker, Checkbox, Radio } from 'antd';

interface ConfigAddOrEditModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues?: any; // 支持传递初始值
}

const ConfigAddOrEditModal: React.FC<ConfigAddOrEditModalProps> = ({ visible, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm();

  // 当 initialValues 发生变化时，重置表单的初始值，确保所有字段都设置初始值
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  // 表单提交时，将未显示在表单中的字段也透传到服务器
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const combinedValues = { ...initialValues, ...values }; // 合并表单数据和初始数据
        onOk(combinedValues); // 将完整数据传递给父组件
        form.resetFields(); // 重置表单
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="添加/编辑系统配置表"
      open={visible}
      onCancel={() => {
        form.resetFields(); // 重置表单
        onCancel(); // 调用取消处理函数
      }}
      onOk={handleOk} // 在表单提交时调用 handleOk 函数
    >
      <Form form={form} layout="vertical" name="addOrEditConfigForm" initialValues={initialValues}>
        <Form.Item
          name="configName"
          label="配置名称"
          rules={[
            { required: true, message: '请输入配置名称' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="configDesc"
          label="描述信息"
          rules={[
            { required: true, message: '请输入描述信息' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="groupKey"
          label="分组KEY"
          rules={[
            { required: true, message: '请输入分组KEY' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="groupName"
          label="分组名称"
          rules={[
            { required: true, message: '请输入分组名称' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="configVal"
          label="配置内容项"
          rules={[
            { required: true, message: '请输入配置内容项' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="配置类型"
          rules={[
            { required: true, message: '请输入配置类型' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sortNum"
          label="显示顺序"
          rules={[
            { required: true, message: '请输入显示顺序' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConfigAddOrEditModal;
