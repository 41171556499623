/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, DatePicker, Checkbox, Radio } from 'antd';

interface UserAddOrEditModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues?: any; // 支持传递初始值
}

const UserAddOrEditModal: React.FC<UserAddOrEditModalProps> = ({ visible, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm();

  // 当 initialValues 发生变化时，重置表单的初始值，确保所有字段都设置初始值
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  // 表单提交时，将未显示在表单中的字段也透传到服务器
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const combinedValues = { ...initialValues, ...values }; // 合并表单数据和初始数据
        onOk(combinedValues); // 将完整数据传递给父组件
        form.resetFields(); // 重置表单
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="添加/编辑用户表"
      open={visible}
      onCancel={() => {
        form.resetFields(); // 重置表单
        onCancel(); // 调用取消处理函数
      }}
      onOk={handleOk} // 在表单提交时调用 handleOk 函数
    >
      <Form form={form} layout="vertical" name="addOrEditUserForm" initialValues={initialValues}>
        <Form.Item
          name="loginUsername"
          label="登录用户名"
          rules={[
            { required: true, message: '请输入登录用户名' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="realname"
          label="姓名"
          rules={[
            { required: true, message: '请输入姓名' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="telephone"
          label="手机号"
          rules={[
            { required: true, message: '请输入手机号' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sex"
          label="性别"
          rules={[
            { required: true, message: '请选择性别' }
          ]}
        >
          <Radio.Group>
            <Radio value={1}>男</Radio>
            <Radio value={2}>女</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="userNo"
          label="员工编号"
          rules={[
            { required: false, message: '请输入员工编号' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="inviteCode"
          label="邀请码"
          rules={[
            { required: false, message: '请输入邀请码' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="userType"
          label="用户类型"
          rules={[
            { required: true, message: '请选择用户类型' }
          ]}
        >
          <Radio.Group>
            <Radio value={1}>超级管理员</Radio>
            <Radio value={2}>普通用户</Radio>
            <Radio value={3}>拓展员</Radio>
            <Radio value={11}>店长</Radio>
            <Radio value={12}>店员</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="teamId"
          label="团队ID"
          rules={[
            { required: false, message: '请输入团队ID' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          name="isTeamLeader"
          label="是否队长"
          rules={[
            { required: true, message: '请选择是否队长' }
          ]}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="state"
          label="状态"
          rules={[
            { required: true, message: '请选择状态' }
          ]}
        >
          <Radio.Group>
            <Radio value={1}>启用</Radio>
            <Radio value={0}>停用</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="sysType"
          label="所属系统"
          rules={[
            { required: true, message: '请输入所属系统' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="safeWord"
          label="安全预留信息"
          rules={[
            { required: false, message: '请输入安全预留信息' }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserAddOrEditModal;
