import { Route, RouteObject } from 'react-router-dom';
import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

// 渲染路由的函数
export const renderRoutes = (routes: RouteObject[]) => {
  return routes.map((route, index) => {
    if (route.children) {
      // 如果有子路由，递归渲染
      return (
        <Route
          key={index}
          path={route.path}
          element={route.element}
          index={route.index}
        >
          {renderRoutes(route.children)}
        </Route>
      );
    }

    return (
      <Route
        key={index}
        path={route.path}
        element={route.element}
        index={route.index}
      />
    );
  });
};

// 假设 MenuData 是菜单数据结构的类型
interface MenuData {
  key: string;
  name: string;
  path?: string;
  icon?: React.ReactNode;
  children?: MenuData[];
}

// 递归渲染菜单的函数
export const renderMenus = (menuData: MenuData[]) => {
  return menuData.map((menu) => {
    if (menu.children && menu.children.length > 0) {
      // 如果有子菜单，递归渲染 SubMenu
      return (
        <SubMenu key={menu.key} icon={menu.icon} title={menu.name}>
          {renderMenus(menu.children)}
        </SubMenu>
      );
    }

    // 渲染单个 Menu.Item
    return (
      <Menu.Item key={menu.key} icon={menu.icon}>
        <Link to={menu.path || ''}>{menu.name}</Link>
      </Menu.Item>
    );
  });
};
