import React, { useState } from 'react';
import { Modal } from 'antd';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageTestPage: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null); // 用于裁剪
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25
  }); // 设置初始裁剪状态
  const [modalVisible, setModalVisible] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null); // 保存裁剪后的图片
  const [showCropModal, setShowCropModal] = useState(false); // 用于控制裁剪区域的 Modal 显示

  const placeholderImages = [
    "https://placehold.co/100X100/png",
    "https://placehold.co/200X200/png",
    "https://placehold.co/300X300/png",
    "https://placehold.co/400X400/png",
    "https://placehold.co/500X500/png",
  ];

  // 点击图片放大查看
  const handleZoomImageClick = (src: string) => {
    setSelectedImage(src);
  };

  // 点击图片进入裁剪模式
  const handleCropImageClick = (src: string) => {
    setSelectedImage(src);
    setShowCropModal(true); // 用于裁剪功能的 Modal
  };

  // 关闭放大 Modal
  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedImage(null);
  };

  // 关闭裁剪 Modal
  const handleCropModalClose = () => {
    setShowCropModal(false);
    setSelectedImage(null);
  };

  // 裁剪图片后的处理
  const onCropComplete = (crop: PixelCrop) => {
    if (selectedImage && crop.width && crop.height) {
      generateCroppedImage(selectedImage, crop);
    }
  };

  // 生成裁剪后的图片
  const generateCroppedImage = async (imageSrc: string, crop: PixelCrop) => {
    const image = new Image();
    image.crossOrigin = "anonymous"; // 确保图片加载允许跨域操作
    image.src = imageSrc;
    await new Promise<void>((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL('image/jpeg');
      setCroppedImageUrl(base64Image);
    }
  };

  return (
    <div style={{ padding: '40px', textAlign: 'center' }}>
      <h2>Image 测试页面</h2>

      {/* 第一部分：只用于放大的图片 */}
      <h3>放大图片区域</h3>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        {placeholderImages.slice(0, 5).map((src, index) => (
          <Zoom key={index}>
            <img
              src={src}
              alt={`Zoom Placeholder ${index + 1}`}
              style={{
                width: '200px',
                height: '200px',
                objectFit: 'cover',
                borderRadius: '8px',
                border: '1px solid #ddd',
                cursor: 'pointer'
              }}
              onClick={() => handleZoomImageClick(src)} // 点击时只放大图片
            />
          </Zoom>
        ))}
      </div>

      {/* 第二部分：用于裁剪图片 */}
      <h3 style={{ marginTop: '40px' }}>裁剪图片区域</h3>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        {placeholderImages.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Crop Placeholder ${index + 1}`}
            style={{
              width: '200px',
              height: '200px',
              objectFit: 'cover',
              borderRadius: '8px',
              border: '1px solid #ddd',
              cursor: 'pointer'
            }}
            onClick={() => handleCropImageClick(src)} // 点击时进入裁剪模式
          />
        ))}
      </div>

      {/* Modal 放大图片 */}
      <Modal
        open={modalVisible}
        footer={null}
        onCancel={handleModalClose}
        width={600}
      >
        {selectedImage && (
          <img src={selectedImage} alt="Zoomed" style={{ width: '100%' }} />
        )}
      </Modal>

      {/* Modal 裁剪图片 */}
      <Modal
        open={showCropModal}
        footer={null}
        onCancel={handleCropModalClose}
        width={600}
      >
        {selectedImage && (
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            aspect={1} // 设置裁剪比例
            onComplete={onCropComplete} // 裁剪完成时调用
          >
            <img src={selectedImage} alt="Selected for Crop" style={{ width: '100%' }} />
          </ReactCrop>
        )}
      </Modal>

      {/* 显示裁剪后的图片，居中显示 */}
      {croppedImageUrl && (
        <div
          style={{
            marginTop: '40px',
            display: 'flex',
            justifyContent: 'center', // 水平居中
            alignItems: 'center', // 垂直居中
            height: '300px' // 可调整高度，使裁剪后的图片垂直居中
          }}
        >
          <div>
            <h3>裁剪后的图片:</h3>
            <img
              src={croppedImageUrl}
              alt="Cropped"
              style={{
                width: '200px',
                height: '200px',
                objectFit: 'cover',
                borderRadius: '8px',
                border: '1px solid #ddd'
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageTestPage;