import React from "react";
import { Modal } from "antd";
import { QRCodeCanvas } from "qrcode.react"; // 从 'qrcode.react' 导入 QRCodeCanvas

interface CashierQrCodeModal2Props {
  visible: boolean;
  qrCodeUrl: string; // URL 用于生成二维码
  imageUrl?: string; // 可选的图片 URL 参数，如果存在则显示图片
  onOk: () => void;
  onCancel: () => void;
}

const CashierQrCodeModal2: React.FC<CashierQrCodeModal2Props> = ({
  visible,
  qrCodeUrl,
  imageUrl, // 添加 imageUrl 参数
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      title="扫码支付"
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* 如果 imageUrl 存在，则显示图片，否则使用 QRCodeCanvas 生成二维码 */}
        {imageUrl ? (
          <img
            src={imageUrl} // 显示传入的图片 URL
            alt="二维码" // 图片的替代文本
            style={{ width: 200, height: 200 }} // 设置图片大小
          />
        ) : (
          <QRCodeCanvas
            value={qrCodeUrl} // 传入 URL 生成二维码
            size={200} // 设置二维码大小
          />
        )}
        <p>支持微信、支付宝扫码</p>
      </div>
    </Modal>
  );
};

export default CashierQrCodeModal2;