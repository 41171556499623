/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */


import React from 'react';
import { Table, Button, message, Space } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import { tableColumnsEntitlement } from './tableColumnsEntitlement'; // 从独立文件中导入表格列定义
import EntitlementAddOrEditModal from './EntitlementAddOrEditModal'; // 用于添加和编辑用户的表单 Modal 组件
import { Entitlement } from './Entitlement';
import { fetchEntitlementList, addEntitlement, editEntitlement, deleteEntitlement } from './apiEntitlement'; // 导入封装的 API
import { useNavigate, useLocation } from 'react-router-dom';

const EntitlementListPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 获取当前路径信息
  const [data, setData] = React.useState<Entitlement[]>([]);
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [editingEntitlement, setEditingEntitlement] = React.useState<Entitlement | null>(null); // 用于编辑的Entitlement

  // 判断是否为管理员路径
  const isAdminPath = location.pathname.includes('/admin');

  // 请求数据函数，自动切换 mock 数据或真实数据
  const fetchTableData = async (page: number = 1, pageSize: number = 10) => {
    setLoading(true);
    try {
      const response = await fetchEntitlementList(page, pageSize);
      setData(response.data.records);
      setPagination({
        current: response.data.current,
        pageSize: response.data.size,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载数据
  React.useEffect(() => {
    fetchTableData();
  }, []);

  // 显示 Modal 表单
  const showAddEntitlementModal = () => {
    setEditingEntitlement(null); // 添加时不编辑任何Entitlement
    setIsModalVisible(true);
  };

  // 关闭 Modal 表单
  const handleCancel = () => {
    setIsModalVisible(false);
    setTimeout(() => {
       setEditingEntitlement(null); // Modal 关闭后重置编辑状态
    }, 100);
  };

  // 提交表单，添加或编辑Entitlement
  const handleAddOrEditEntitlement = async (values: any) => {
    try {
      if (editingEntitlement) {
        // 编辑模式
        await editEntitlement(editingEntitlement.entId, values); // 调用封装的 editEntitlement API
        const updatedData = data.map((item) =>
          item.entId === editingEntitlement.entId ? { ...editingEntitlement, ...values } : item
        );
        setData(updatedData);
        message.success('Entitlement信息更新成功');
      } else {
        // 添加模式
        const newEntitlement: Entitlement = {
          entId: data.length + 1, // 模拟自增ID
          ...values,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        await addEntitlement(newEntitlement); // 调用封装的 addEntitlement API
        setData([...data, newEntitlement]); // 添加新Entitlement到表格
        message.success('Entitlement添加成功');
      }
    } catch (error) {
      console.error('Error adding or editing Entitlement:', error);
    } finally {
      setIsModalVisible(false); // 关闭Modal
    }
  };

  // 查看按钮点击处理函数
  const handleView = (record: Entitlement) => {
    console.log('查看Entitlement：', record);
    const detailPath = isAdminPath ? '/test/admin/entitlement-detail' : '/test/entitlement-detail';
    navigate(`${detailPath}/${record.entId}`); // 根据当前路径跳转到不同的详情页
  };

  // 编辑Entitlement
  const handleEdit = (record: Entitlement) => {
    setEditingEntitlement(record); // 设置当前编辑的Entitlement
    setIsModalVisible(true);
  };

  // 删除Entitlement
  const handleDelete = async (id: string) => {
    try {
      await deleteEntitlement(id); // 调用封装的 deleteEntitlement API
      const updatedData = data.filter((item) => item.entId !== id);
      setData(updatedData); // 更新表格数据
      message.success('Entitlement删除成功');
    } catch (error) {
      console.error('Error deleting Entitlement:', error);
    }
  };

  // 处理分页改变
  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchTableData(pagination.current, pagination.pageSize);
  };

  // 扩展表格列，添加操作按钮
  const extendedColumns = [
    ...tableColumnsEntitlement,
    {
      title: '操作',
      key: 'operate',
      render: (text: any, record: Entitlement) => (
        <div style={{ textAlign: 'left' }}>
          <Space>
            <Button type="default" onClick={() => handleView(record)} style={{ marginRight: 8 }}>
              查看
            </Button>
            <Button type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>
              修改
            </Button>
            <Button type="primary" danger onClick={() => handleDelete(record.entId)}>
              删除
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6">
      {!isAdminPath && (
        <h2 className="text-1xl font-bold text-center mb-4">Entitlement Management</h2>
      )}
      <Button type="primary" onClick={showAddEntitlementModal} className="mb-4">
        添加Entitlement
      </Button>
      <Table
        columns={extendedColumns}
        dataSource={data}
        rowKey="entId"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <EntitlementAddOrEditModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleAddOrEditEntitlement}
        initialValues={editingEntitlement}
        key={editingEntitlement ? editingEntitlement.entId : 'add-entitlement'} // 强制刷新
      />
    </div>
  );
};

export default EntitlementListPage;
