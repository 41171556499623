/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/request';
import {
  getEntitlementMockData,
  mockAddEntitlement,
  mockEditEntitlement,
  mockDeleteEntitlement,
  mockFetchEntitlementDetail
} from './mockEntitlementData'; // 导入 Mock 函数

const USE_MOCK_DATA = true; // 切换 Mock 数据标志

// 获取Entitlement列表的请求
export const fetchEntitlementList = (page: number, pageSize: number) => {
  if (USE_MOCK_DATA) {
    return getEntitlementMockData(page, pageSize);
  }
  return getRequest('/api/v1/test/entitlement/page', {
    page,
    size: pageSize,
  });
};

// 添加新Entitlement的请求
export const addEntitlement = (data: any) => {
  if (USE_MOCK_DATA) {
    return mockAddEntitlement(data);
  }
  return postRequest('/api/v1/test/entitlement', data);
};

// 编辑Entitlement的请求
export const editEntitlement = (id: string, data: any) => {
  if (USE_MOCK_DATA) {
    return mockEditEntitlement(id, data);
  }
  return putRequest(`/api/v1/test/entitlement/${id}`, data);
};

// 删除Entitlement的请求
export const deleteEntitlement = (id: string) => {
  if (USE_MOCK_DATA) {
    return mockDeleteEntitlement(id);
  }
  return deleteRequest(`/api/v1/test/entitlement/${id}`);
};

// 获取Entitlement详情的请求
export const fetchEntitlementDetail = (id: string) => {
  if (USE_MOCK_DATA) {
    return mockFetchEntitlementDetail(id);
  }
  return getRequest(`/api/v1/test/entitlement/${id}`);
};
