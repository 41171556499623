/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import Mock, { generateMockTemplate } from '../../manual/mock';

// 定义规则映射（配置文件）
const rulesMap: any = {

  id: '@increment', // 书籍ID
  title: '@title(3, 5)', // 书籍标题
  author: '@name', // 作者
  description: '@paragraph(1, 3)', // 书籍简介
  category: '@pick(["Fiction", "Non-Fiction", "Science", "Biography", "Fantasy", "History", "Children", "Self-Help"])', // 书籍分类
  isbn: '@natural(1000000000000, 9999999999999)', // 国际标准书号
  tags: '@pick(["Bestseller", "New Arrival", "Classic", "Award Winning", "Recommended", "Series"])', // 书籍标签
  imageUrl: '@image("200x200", "#FFCC33", "#FFF", "Book")', // 图片网址
  publishedDate: '@date("yyyy-MM-dd")', // 出版日期
  price: '@float(5, 100, 2, 2)', // 书籍价格
  createdAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 创建时间
  updatedAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 更新时间
};

// 生成 mock 数据模板
const bookTemplate: any = generateMockTemplate(rulesMap);

// 生成 mock 数据
let mockBookData = Mock.mock({
  'books|100': [bookTemplate] // 生成100条数据
}).books;

// 获取Book列表（模拟的分页）
export const getBookMockData = (page: number, pageSize: number): Promise<any> => {
  const start = (page - 1) * pageSize;
  const records = mockBookData.slice(start, start + pageSize);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        errorCode: 0,
        message: 'Request processed successfully',
        data: {
          records,
          total: mockBookData.length,
          size: pageSize,
          current: page,
          pages: Math.ceil(mockBookData.length / pageSize),
        },
      });
    }, 50);
  });
};

// 模拟添加Book
export const mockAddBook = (newBook: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockBookData.push({
      ...newBook,
      id: mockBookData.length + 1,
      createdAt: now,
      updatedAt: now
    });
    setTimeout(() => resolve({ errorCode: 0, message: 'Book added successfully' }), 50);
  });
};

// 模拟编辑Book
export const mockEditBook = (id: number, updatedBook: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockBookData = mockBookData.map((item:any) =>
      (item.id === id ? { ...item, ...updatedBook, updatedAt: now } : item)
    );
    setTimeout(() => resolve({ errorCode: 0, message: 'Book updated successfully' }), 50);
  });
};

// 模拟删除Book
export const mockDeleteBook = (id: number): Promise<any> => {
  return new Promise((resolve) => {
    mockBookData = mockBookData.filter((item:any) => item.id !== id);
    setTimeout(() => resolve({ errorCode: 0, message: 'Book deleted successfully' }), 50);
  });
};

// 获取Book详情（模拟）
export const mockFetchBookDetail = (id: number): Promise<any> => {
  const item = mockBookData.find((item:any) => item.id === id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (item) {
        resolve({ data: item }); // 返回找到的Book
      } else {
        reject(new Error('Book not found')); // 未找到Book时返回错误
      }
    }, 50); // 模拟延迟50ms
  });
};
