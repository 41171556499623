import axios from 'axios';
import * as CryptoJS from 'crypto-js';
import { PayOrderResult2 } from '../Pay';

// 构建 Berapay 支付请求参数
export const constructBerapayRequest = (
  wayCode: string,
  bizOrderNo: string,
  totalMoney: number,
  title: string,
  allocation: boolean,
  channelExtra: any
) => {
  const params: any = {
    mchNo: 'M1716461083', // 替换为实际商户号
    appId: '664f1e1be4b0c594ed2b3d7b', // 替换为实际应用ID
    mchOrderNo: bizOrderNo,
    wayCode: wayCode,
    amount: Math.round(totalMoney * 100), // 金额转为分
    currency: 'cny',
    subject: title,
    body: '支付描述',
    notifyUrl: 'https://pay.suantai.com/api/v1/test/anon/paytestNotify/payOrder', // 替换为实际通知URL
    reqTime: Date.now(),
    version: '1.0',
    signType: 'MD5',
    channelExtra: JSON.stringify(channelExtra),
  };

  if (allocation) {
    params.divisionMode = 1; // 自动分账
  }

  params.sign = calculateSign(params);

  return params;
};

// 计算签名
const calculateSign = (params: any) => {
  const secretKey =
    'uivesUliASLvWy8wdNKxcNx155zJurhBXTahJTrl9oHToPTzrBcog3Lx4T1bQVqPswCMC8kne2xrdMWbyuS4cTu9PBBLsMsnx9gmGIGqGqXganFaFNSwJtybxq0xuHIk'; // 替换为实际密钥
  const filteredKeys = Object.keys(params).filter(
    (key) => key !== 'sign' && params[key] !== null && params[key] !== ''
  );
  const sortedKeys = filteredKeys.sort();
  const paramString = sortedKeys.map((key) => `${key}=${params[key]}`).join('&');
  const stringToSign = `${paramString}&key=${secretKey}`;
  return CryptoJS.MD5(stringToSign).toString().toUpperCase();
};

// 发送 Berapay 支付请求
export const sendBerapayRequest = async (params: any) => {
  try {
    const response = await axios.post(
      'https://pay.huojin.com/api/pay/unifiedOrder',
      params,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Berapay API 请求错误:', error);
    throw error;
  }
};

// 处理 Berapay 支付响应并返回 PayOrderResult2 类型
export const handleBerapayResponse = (
    data: any,
    setQrModalUrl: (url: string) => void,
    setQrModalTitle: (title: string) => void,
    setQrCodeModalVisible: (visible: boolean) => void,
    checkPayStatus: () => void
  ): PayOrderResult2 => {
    if (data.code === 0) {
      const { payDataType, payData, payOrderId, mchOrderNo, orderState } = data.data;

      console.log("=============================")
      console.log(data)
      switch (payDataType) {
        case 'codeImgUrl':
        case 'codeUrl':
          // 处理二维码支付
          setQrModalUrl(payData);
          setQrModalTitle('请使用支付宝或微信扫码支付');
          setQrCodeModalVisible(true);
          checkPayStatus(); // 开始轮询支付状态
          break;

        case 'payurl':
          // 处理跳转链接支付
          // window.open(payData, '_blank');
          // console.log(payData)
          break;

        case 'form':
          // 处理表单支付
          const formDiv = document.createElement('div');
          formDiv.innerHTML = payData;
          document.body.appendChild(formDiv);
          (formDiv.querySelector('form') as HTMLFormElement).submit();
          break;

        case 'wxapp':
        case 'aliapp':
        case 'ysfapp':
          // 处理移动端 App 支付 (微信、支付宝、云闪付)
          console.log(`处理 App 支付参数: ${payDataType}`);
          // 可以在这里根据实际情况调用对应的 SDK 进行处理
          break;

        case 'none':
          // 无需支付参数，直接处理
          console.log('无需支付参数');
          break;

        default:
          throw new Error(`不支持的 payDataType: ${payDataType}`);
      }

      // 返回符合 PayOrderResult2 类型的数据
      return {
        code: 0,
        msg: 'Success',
        data: {
          payOrderId: payOrderId || '默认的payOrderId', // 需要提供的默认值
          mchOrderNo: mchOrderNo || '默认的mchOrderNo', // 需要提供的默认值
          orderState: orderState || 1, // 设置默认的订单状态
          payDataType: payDataType,
          payData: payData,
          errCode: null,
          errMsg: null
        }
      };
    } else {
      throw new Error(`支付发起失败: ${data.msg}`);
    }
  };

// 检查支付状态
export const checkPayStatus = (bizOrderNo: string, callback: (status: boolean) => void) => {
  // 在此处轮询或通过 WebSocket 实现支付状态的检查逻辑
  const intervalId = setInterval(async () => {
    try {
      const response = await axios.get(`/api/pay/queryPayOrderStatus?bizOrderNo=${bizOrderNo}`);
      const { data } = response;
      if (data && data.status === 2) {
        callback(true); // 支付成功
        clearInterval(intervalId);
      } else if (data && data.status === 3) {
        callback(false); // 支付失败
        clearInterval(intervalId);
      }
    } catch (error) {
      console.error('支付状态查询失败:', error);
    }
  }, 3000); // 每隔 3 秒轮询一次支付状态
};