// src/layout/AdminLayout.tsx

import React, { useState } from 'react';
import { Layout, Menu, Avatar, Dropdown, message } from 'antd';
import { Outlet, Link } from 'react-router-dom'; // 引入 Link 组件
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  DashboardOutlined,
  SettingOutlined,
  AppstoreOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import './AdminLayout.css'; // 引入自定义样式

import { testMenus } from '../route';
import { renderMenus } from '../route/entry';

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const AdminLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    message.success('登出成功');
    // 这里可以添加登出的逻辑，例如清除 token 等操作
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined /> 登出
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">Logo</div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['/admin/dashboard']}>
          <Menu.Item key="/test/dashboard" icon={<DashboardOutlined />}>
            <Link to="/test/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/test/products" icon={<AppstoreOutlined />}>
            <Link to="/test/products">产品管理</Link>
          </Menu.Item>
          <SubMenu key="users" icon={<TeamOutlined />} title="功能列表">
            {renderMenus(testMenus)}
          </SubMenu>
          <SubMenu key="settings" icon={<SettingOutlined />} title="系统设置">
            <Menu.Item key="/test/settings/general">
              <Link to="/test/settings/general">通用设置</Link>
            </Menu.Item>
            <Menu.Item key="/test/settings/security">
              <Link to="/test/settings/security">安全设置</Link>
            </Menu.Item>
          </SubMenu>
          {/* 更多的菜单项可以继续添加 */}
        </Menu>
      </Sider>
      <Layout>
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: toggle,
            }
          )}
          <div style={{ float: 'right', marginRight: 20 }}>
            <Dropdown overlay={menu} placement="bottomRight">
              <Avatar size="large" icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: '#fff',
          }}
        >
          <Outlet /> {/* 渲染嵌套路由的内容 */}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          ©2024 上海火金网络科技有限公司. All Rights Reserved.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;