/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import type { ColumnsType } from 'antd/es/table';
import { Config } from './Config';

// 生成 Config 表格列配置
export const tableColumnsConfig: ColumnsType<Config> = [
  {
    title: '配置KEY',
    dataIndex: 'configKey',
    key: 'configKey',
  },
  {
    title: '配置名称',
    dataIndex: 'configName',
    key: 'configName',
  },
  {
    title: '分组KEY',
    dataIndex: 'groupKey',
    key: 'groupKey',
  },
  {
    title: '分组名称',
    dataIndex: 'groupName',
    key: 'groupName',
  },
  {
    title: '配置内容项',
    dataIndex: 'configVal',
    key: 'configVal',
  },
  {
    title: '配置类型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '显示顺序',
    dataIndex: 'sortNum',
    key: 'sortNum',
  }
];
