/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/request';
import {
  getArticleMockData,
  mockAddArticle,
  mockEditArticle,
  mockDeleteArticle,
  mockFetchArticleDetail
} from './mockArticleData'; // 导入 Mock 函数

const USE_MOCK_DATA = true; // 切换 Mock 数据标志

// 获取Article列表的请求
export const fetchArticleList = (page: number, pageSize: number) => {
  if (USE_MOCK_DATA) {
    return getArticleMockData(page, pageSize);
  }
  return getRequest('/api/v1/test/article/page', {
    page,
    size: pageSize,
  });
};

// 添加新Article的请求
export const addArticle = (data: any) => {
  if (USE_MOCK_DATA) {
    return mockAddArticle(data);
  }
  return postRequest('/api/v1/test/article', data);
};

// 编辑Article的请求
export const editArticle = (id: number, data: any) => {
  if (USE_MOCK_DATA) {
    return mockEditArticle(id, data);
  }
  return putRequest(`/api/v1/test/article/${id}`, data);
};

// 删除Article的请求
export const deleteArticle = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockDeleteArticle(id);
  }
  return deleteRequest(`/api/v1/test/article/${id}`);
};

// 获取Article详情的请求
export const fetchArticleDetail = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockFetchArticleDetail(id);
  }
  return getRequest(`/api/v1/test/article/${id}`);
};
