/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */


import React from 'react';
import { Table, Button, message, Space } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import { tableColumnsUserAuth } from './tableColumnsUserAuth'; // 从独立文件中导入表格列定义
import UserAuthAddOrEditModal from './UserAuthAddOrEditModal'; // 用于添加和编辑用户的表单 Modal 组件
import { UserAuth } from './UserAuth';
import { fetchUserAuthList, addUserAuth, editUserAuth, deleteUserAuth } from './apiUserAuth'; // 导入封装的 API
import { useNavigate, useLocation } from 'react-router-dom';

const UserAuthListPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 获取当前路径信息
  const [data, setData] = React.useState<UserAuth[]>([]);
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [editingUserAuth, setEditingUserAuth] = React.useState<UserAuth | null>(null); // 用于编辑的UserAuth

  // 判断是否为管理员路径
  const isAdminPath = location.pathname.includes('/admin');

  // 请求数据函数，自动切换 mock 数据或真实数据
  const fetchTableData = async (page: number = 1, pageSize: number = 10) => {
    setLoading(true);
    try {
      const response = await fetchUserAuthList(page, pageSize);
      setData(response.data.records);
      setPagination({
        current: response.data.current,
        pageSize: response.data.size,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载数据
  React.useEffect(() => {
    fetchTableData();
  }, []);

  // 显示 Modal 表单
  const showAddUserAuthModal = () => {
    setEditingUserAuth(null); // 添加时不编辑任何UserAuth
    setIsModalVisible(true);
  };

  // 关闭 Modal 表单
  const handleCancel = () => {
    setIsModalVisible(false);
    setTimeout(() => {
       setEditingUserAuth(null); // Modal 关闭后重置编辑状态
    }, 100);
  };

  // 提交表单，添加或编辑UserAuth
  const handleAddOrEditUserAuth = async (values: any) => {
    try {
      if (editingUserAuth) {
        // 编辑模式
        await editUserAuth(editingUserAuth.authId, values); // 调用封装的 editUserAuth API
        const updatedData = data.map((item) =>
          item.authId === editingUserAuth.authId ? { ...editingUserAuth, ...values } : item
        );
        setData(updatedData);
        message.success('UserAuth信息更新成功');
      } else {
        // 添加模式
        const newUserAuth: UserAuth = {
          authId: data.length + 1, // 模拟自增ID
          ...values,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        await addUserAuth(newUserAuth); // 调用封装的 addUserAuth API
        setData([...data, newUserAuth]); // 添加新UserAuth到表格
        message.success('UserAuth添加成功');
      }
    } catch (error) {
      console.error('Error adding or editing UserAuth:', error);
    } finally {
      setIsModalVisible(false); // 关闭Modal
    }
  };

  // 查看按钮点击处理函数
  const handleView = (record: UserAuth) => {
    console.log('查看UserAuth：', record);
    const detailPath = isAdminPath ? '/test/admin/userauth-detail' : '/test/userauth-detail';
    navigate(`${detailPath}/${record.authId}`); // 根据当前路径跳转到不同的详情页
  };

  // 编辑UserAuth
  const handleEdit = (record: UserAuth) => {
    setEditingUserAuth(record); // 设置当前编辑的UserAuth
    setIsModalVisible(true);
  };

  // 删除UserAuth
  const handleDelete = async (id: number) => {
    try {
      await deleteUserAuth(id); // 调用封装的 deleteUserAuth API
      const updatedData = data.filter((item) => item.authId !== id);
      setData(updatedData); // 更新表格数据
      message.success('UserAuth删除成功');
    } catch (error) {
      console.error('Error deleting UserAuth:', error);
    }
  };

  // 处理分页改变
  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchTableData(pagination.current, pagination.pageSize);
  };

  // 扩展表格列，添加操作按钮
  const extendedColumns = [
    ...tableColumnsUserAuth,
    {
      title: '操作',
      key: 'operate',
      render: (text: any, record: UserAuth) => (
        <div style={{ textAlign: 'left' }}>
          <Space>
            <Button type="default" onClick={() => handleView(record)} style={{ marginRight: 8 }}>
              查看
            </Button>
            <Button type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>
              修改
            </Button>
            <Button type="primary" danger onClick={() => handleDelete(record.authId)}>
              删除
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6">
      {!isAdminPath && (
        <h2 className="text-1xl font-bold text-center mb-4">UserAuth Management</h2>
      )}
      <Button type="primary" onClick={showAddUserAuthModal} className="mb-4">
        添加UserAuth
      </Button>
      <Table
        columns={extendedColumns}
        dataSource={data}
        rowKey="authId"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <UserAuthAddOrEditModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleAddOrEditUserAuth}
        initialValues={editingUserAuth}
        key={editingUserAuth ? editingUserAuth.authId : 'add-userauth'} // 强制刷新
      />
    </div>
  );
};

export default UserAuthListPage;
