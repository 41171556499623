/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, DatePicker, Checkbox, Radio } from 'antd';

interface UserAuthAddOrEditModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues?: any; // 支持传递初始值
}

const UserAuthAddOrEditModal: React.FC<UserAuthAddOrEditModalProps> = ({ visible, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm();

  // 当 initialValues 发生变化时，重置表单的初始值，确保所有字段都设置初始值
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  // 表单提交时，将未显示在表单中的字段也透传到服务器
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const combinedValues = { ...initialValues, ...values }; // 合并表单数据和初始数据
        onOk(combinedValues); // 将完整数据传递给父组件
        form.resetFields(); // 重置表单
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="添加/编辑用户授权表"
      open={visible}
      onCancel={() => {
        form.resetFields(); // 重置表单
        onCancel(); // 调用取消处理函数
      }}
      onOk={handleOk} // 在表单提交时调用 handleOk 函数
    >
      <Form form={form} layout="vertical" name="addOrEditUserAuthForm" initialValues={initialValues}>
        <Form.Item
          name="identityType"
          label="登录类型"
          rules={[
            { required: true, message: '请选择登录类型' }
          ]}
        >
          <Radio.Group>
            <Radio value={1}>登录账号</Radio>
            <Radio value={2}>手机号</Radio>
            <Radio value={3}>邮箱</Radio>
            <Radio value={10}>微信</Radio>
            <Radio value={11}>QQ</Radio>
            <Radio value={12}>支付宝</Radio>
            <Radio value={13}>微博</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="identifier"
          label="认证标识（用户名 ｜open_id）"
          rules={[
            { required: true, message: '请输入认证标识（用户名 ｜open_id）' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="credential"
          label="密码凭据"
          rules={[
            { required: true, message: '请输入密码凭据' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="salt"
          label="SALT"
          rules={[
            { required: true, message: '请输入SALT' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sysType"
          label="所属系统"
          rules={[
            { required: true, message: '请输入所属系统' }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserAuthAddOrEditModal;
