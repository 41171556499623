/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import Mock, { generateMockTemplate } from '../../manual/mock';

// 定义规则映射（配置文件）
const rulesMap: any = {

  entId: '@guid', // 权限ID[ENT_功能模块_子模块_操作], eg: ENT_ROLE_LIST_ADD
  entName: '@name', // 权限名称
  menuIcon: '@image("200x200", "#ADD8E6", "#FFF", "Menu")', // 菜单图标
  menuUri: '@name', // 菜单uri/路由地址
  componentName: '@name', // 组件Name（前后端分离使用）
  entType: '@pick(["ML", "MO", "PB"])', // 权限类型 ML-左侧显示菜单, MO-其他菜单, PB-页面/按钮'
  quickJump: '@pick([0,1])', // 快速开始菜单 0-否, 1-是
  state: '@pick([0,1])', // 状态 0-停用, 1-启用
  pid: '@natural(1, 100)', // 父ID
  entSort: '@name', // 排序字段, 规则：正序
  sysType: '@name', // 所属系统： 参考：SYS_ROLE_TYPE
  matchRule: '@name', // 菜单匹配规则，具体规则匹配详见程序说明
  createdAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 创建时间
  updatedAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 更新时间
};

// 生成 mock 数据模板
const entitlementTemplate: any = generateMockTemplate(rulesMap);

// 生成 mock 数据
let mockEntitlementData = Mock.mock({
  'entitlements|100': [entitlementTemplate] // 生成100条数据
}).entitlements;

// 获取Entitlement列表（模拟的分页）
export const getEntitlementMockData = (page: number, pageSize: number): Promise<any> => {
  const start = (page - 1) * pageSize;
  const records = mockEntitlementData.slice(start, start + pageSize);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        errorCode: 0,
        message: 'Request processed successfully',
        data: {
          records,
          total: mockEntitlementData.length,
          size: pageSize,
          current: page,
          pages: Math.ceil(mockEntitlementData.length / pageSize),
        },
      });
    }, 50);
  });
};

// 模拟添加Entitlement
export const mockAddEntitlement = (newEntitlement: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockEntitlementData.push({
      ...newEntitlement,
      entId: mockEntitlementData.length + 1,
      createdAt: now,
      updatedAt: now
    });
    setTimeout(() => resolve({ errorCode: 0, message: 'Entitlement added successfully' }), 50);
  });
};

// 模拟编辑Entitlement
export const mockEditEntitlement = (id: string, updatedEntitlement: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockEntitlementData = mockEntitlementData.map((item:any) =>
      (item.entId === id ? { ...item, ...updatedEntitlement, updatedAt: now } : item)
    );
    setTimeout(() => resolve({ errorCode: 0, message: 'Entitlement updated successfully' }), 50);
  });
};

// 模拟删除Entitlement
export const mockDeleteEntitlement = (id: string): Promise<any> => {
  return new Promise((resolve) => {
    mockEntitlementData = mockEntitlementData.filter((item:any) => item.entId !== id);
    setTimeout(() => resolve({ errorCode: 0, message: 'Entitlement deleted successfully' }), 50);
  });
};

// 获取Entitlement详情（模拟）
export const mockFetchEntitlementDetail = (id: string): Promise<any> => {
  const item = mockEntitlementData.find((item:any) => item.entId === id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (item) {
        resolve({ data: item }); // 返回找到的Entitlement
      } else {
        reject(new Error('Entitlement not found')); // 未找到Entitlement时返回错误
      }
    }, 50); // 模拟延迟50ms
  });
};
