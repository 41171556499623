import React from 'react';
import { Outlet } from 'react-router-dom';

const AdminLoginLayout: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center bg-simple-main">
      <Outlet /> {/* This is where the nested routes (children) will be rendered */}
    </div>
  );
};

export default AdminLoginLayout;