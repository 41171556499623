import React from 'react';

const AppFooter: React.FC = () => {
  return (
    <footer className="bg-soft-footer py-4 mt-auto w-full">
      <div className="max-w-7xl mx-auto px-4 text-center text-gray-600 text-sm">
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-gray-700">沪ICP备18015888号-4</a>
          <span className="px-2">|</span>
          <strong>妙码</strong> 由
          <a href="https://www.hainei.com" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-gray-700">上海火金网络科技有限公司</a> 提供支持。版权所有 © 2024
        </p>
      </div>
    </footer>
  );
};

export default AppFooter;