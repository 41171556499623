/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import type { ColumnsType } from 'antd/es/table';
import { Entitlement } from './Entitlement';

// 生成 Entitlement 表格列配置
export const tableColumnsEntitlement: ColumnsType<Entitlement> = [
  {
    title: '权限ID',
    dataIndex: 'entId',
    key: 'entId',
  },
  {
    title: '权限名称',
    dataIndex: 'entName',
    key: 'entName',
  },
  {
    title: '菜单图标',
    dataIndex: 'menuIcon',
    key: 'menuIcon',
    render: (url: string) => <img src={url} alt="图片" style={{ width: '50px' }} />,
  },
  {
    title: '菜单uri/路由地址',
    dataIndex: 'menuUri',
    key: 'menuUri',
  },
  {
    title: '组件名称',
    dataIndex: 'componentName',
    key: 'componentName',
  },
  {
    title: '权限类型',
    dataIndex: 'entType',
    key: 'entType',
  },
  {
    title: '快速开始菜单',
    dataIndex: 'quickJump',
    key: 'quickJump',
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: '父权限ID',
    dataIndex: 'pid',
    key: 'pid',
  },
  {
    title: '排序',
    dataIndex: 'entSort',
    key: 'entSort',
  },
  {
    title: '所属系统',
    dataIndex: 'sysType',
    key: 'sysType',
  },
  {
    title: '菜单匹配规则',
    dataIndex: 'matchRule',
    key: 'matchRule',
  }
];
