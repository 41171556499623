/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/request';
import {
  getConfigMockData,
  mockAddConfig,
  mockEditConfig,
  mockDeleteConfig,
  mockFetchConfigDetail
} from './mockConfigData'; // 导入 Mock 函数

const USE_MOCK_DATA = true; // 切换 Mock 数据标志

// 获取Config列表的请求
export const fetchConfigList = (page: number, pageSize: number) => {
  if (USE_MOCK_DATA) {
    return getConfigMockData(page, pageSize);
  }
  return getRequest('/api/v1/test/config/page', {
    page,
    size: pageSize,
  });
};

// 添加新Config的请求
export const addConfig = (data: any) => {
  if (USE_MOCK_DATA) {
    return mockAddConfig(data);
  }
  return postRequest('/api/v1/test/config', data);
};

// 编辑Config的请求
export const editConfig = (id: string, data: any) => {
  if (USE_MOCK_DATA) {
    return mockEditConfig(id, data);
  }
  return putRequest(`/api/v1/test/config/${id}`, data);
};

// 删除Config的请求
export const deleteConfig = (id: string) => {
  if (USE_MOCK_DATA) {
    return mockDeleteConfig(id);
  }
  return deleteRequest(`/api/v1/test/config/${id}`);
};

// 获取Config详情的请求
export const fetchConfigDetail = (id: string) => {
  if (USE_MOCK_DATA) {
    return mockFetchConfigDetail(id);
  }
  return getRequest(`/api/v1/test/config/${id}`);
};
