import React, { useState } from 'react';
import { Modal, Input, Button, Spin } from 'antd';

type BarcodeModalProps = {
  visible: boolean;
  loading: boolean;
  topTitle: string;
  onOk: (authCode: string) => void;
  onCancel: () => void;
};

const BarcodeModal: React.FC<BarcodeModalProps> = ({ visible, loading, topTitle, onOk, onCancel }) => {
  const [authCode, setAuthCode] = useState('');

  const handleOk = () => {
    onOk(authCode); // 调用父组件的 onOk 方法并传递 authCode
  };

  const handleCancel = () => {
    setAuthCode(''); // 取消时清空输入框内容
    onCancel(); // 调用父组件的 onCancel 方法
  };

  return (
    <Modal open={visible} title="条码支付" width={350} footer={null} onCancel={handleCancel}>
      <Spin spinning={loading}>
        <div>
          <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
            {topTitle}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Input
              allowClear
              value={authCode}
              onChange={(e) => setAuthCode(e.target.value)}
              placeholder="请输入付款条码"
            />
          </div>
        </div>
      </Spin>
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <Button onClick={handleCancel}>取消</Button>
        <Button type="primary" loading={loading} onClick={handleOk} style={{ marginLeft: '10px' }}>
          确定
        </Button>
      </div>
    </Modal>
  );
};

export default BarcodeModal;