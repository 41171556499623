// src/page/DashboardPage.tsx

import React from 'react';
import { Card, Col, Row } from 'antd';

const DashboardPage: React.FC = () => {
  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="统计信息1" bordered={false}>
            内容1
          </Card>
        </Col>
        <Col span={8}>
          <Card title="统计信息2" bordered={false}>
            内容2
          </Card>
        </Col>
        <Col span={8}>
          <Card title="统计信息3" bordered={false}>
            内容3
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardPage;