/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

// route/routeConfig.ts
import { RouteObject, useNavigate } from 'react-router-dom';
import ConfigListPage from '../../logic/auto/config/ConfigListPage';
import ConfigDetailPage from '../../logic/auto/config/ConfigDetailPage';
import { Button } from 'antd';

const configRoutes: RouteObject[] = [
  { path: '/test/config-list', element: <ConfigListPage /> },
  { path: '/test/config-detail/:id', element: <ConfigDetailPage /> },
];

const configAdminRoutes: RouteObject[] = [
  { path: '/test/admin/config-list', element: <ConfigListPage /> },
  { path: '/test/admin/config-detail/:id', element: <ConfigDetailPage /> },
];

// 测试入口组件
export const TestEntry: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button type="default" size="large" onClick={() => navigate('/test/config-list')}>
      ConfigList Test
    </Button>
  );
};

// 测试入口menu
export const menuData: {} = {
  key : "/test/admin/config-list",
  path: "/test/admin/config-list",
  name: "Config管理"
}

export default { routes: configRoutes, adminRoutes: configAdminRoutes, TestEntry, menuData };
