import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import logo from '../assets/images/logo.svg'; // 确保你的logo路径正确

const menuItems = [
  { text: '平台功能', link: '#' },
  { text: '应用方案', link: '#' },
  { text: '模板库', link: '/templates' },
  { text: '价格', link: '#' },
  { text: '社区', link: '#' },
  { text: '帮助', link: '#' },
  { text: '登录', link: '/login' },
  { text: '注册', link: '/register' },
];

const AppHeader: React.FC = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <header className="bg-soft-header shadow py-4">
      <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <img src={logo} alt="妙码" className="h-8 w-8" />
          <span className="text-2xl font-bold">妙码</span>
        </div>
        <button
          className="md:hidden text-gray-500 focus:outline-none"
          onClick={toggleMenu}
        >
          <MenuOutlined className="w-6 h-6" />
        </button>
        <nav className="hidden md:flex space-x-4">
          {menuItems.slice(0, 6).map(item => (
            <Link key={item.text} to={item.link} className="hover:text-gray-700">{item.text}</Link>
          ))}
        </nav>
        <div className="hidden md:flex space-x-2">
          <Link to="/login" className="header-button header-login-button">登录</Link>
          <Link to="/register" className="header-button header-register-button">注册</Link>
        </div>
      </div>
      <nav className={`${isMenuVisible ? 'block' : 'hidden'} md:hidden bg-soft-header w-full z-10`}>
        {menuItems.map(item => (
          <Link key={item.text} to={item.link} className="header-link">{item.text}</Link>
        ))}
      </nav>
    </header>
  );
};

export default AppHeader;