import { getRequest, postRequest } from '../../../utils/request'; // 实际请求工具
import {
  mockSimplePayCashier,
  mockFindStatusByBizOrderNo,
  mockGetUniCashierUrl,
  mockCreateAggregatePayUrl,
  mockAggregateBarCodePay,
  mockCreateOrder,
  mockFetchPaymentData,
} from '../mock/mockPayData'; // 模拟数据
import { PayOrderResponse, PayOrderResult, PayOrderResult2 } from '../Pay';
import { constructBerapayRequest, handleBerapayResponse, sendBerapayRequest } from './berapayService';

const USE_MOCK_DATA = false; // 切换 Mock 数据标志

// 发起简单支付
export const simplePayCashier = (data: any): Promise<PayOrderResult> => {
  if (USE_MOCK_DATA) {
    return mockSimplePayCashier(data);
  }
  return postRequest('/demo/cashier/simplePayCashier', data).then((response) => response.data as PayOrderResult);
};

// 根据业务ID获取支付状态
export const findStatusByBizOrderNo = (bizOrderNo: string): Promise<boolean> => {
  if (USE_MOCK_DATA) {
    return mockFindStatusByBizOrderNo(bizOrderNo);
  }
  return getRequest('/demo/cashier/queryPayOrderSuccess', { bizOrderNo }).then((response) => response.data as boolean);
};

// 获取手机收银台链接
export const getUniCashierUrl = (): Promise<string> => {
  if (USE_MOCK_DATA) {
    return mockGetUniCashierUrl();
  }
  return getRequest('/demo/cashier/getUniCashierUrl').then((response) => response.data as string);
};

// 创建聚合支付码链接
export const createAggregatePayUrl = (data: any): Promise<PayOrderResult> => {
  if (USE_MOCK_DATA) {
    return mockCreateAggregatePayUrl(data);
  }
  return postRequest('/demo/aggregate/createUrl', data).then((response) => response.data as PayOrderResult);
};

// 聚合条码支付
export const aggregateBarCodePay = (data: any): Promise<PayOrderResult> => {
  if (USE_MOCK_DATA) {
    return mockAggregateBarCodePay(data);
  }
  return postRequest('/demo/aggregate/barCodePay', data).then((response) => response.data as PayOrderResult);
};

// 创建支付订单 - 聚合主扫 (公众号支付)
export const createOrder = async (data: any): Promise<PayOrderResult2> => {
  if (USE_MOCK_DATA) {
    return mockCreateOrder(data);
  }

  if (data.wayCode.startsWith('QR_CASHIER_'))
    data.wayCode = "QR_CASHIER"

  // 如果支付方式是聚合主扫 (公众号支付)
  if ( (data.wayCode === 'QR_CASHIER' && (data.entryPageType === 'h5' || data.entryPageType === 'lite'))
    || data.wayCode === 'AUTO_BAR'
    || data.wayCode === 'WEB_CASHIER') {

    // 构建 channelExtra 对象
    let channelExtra: any = {
      entryPageType: data.entryPageType, // 设置 entryPageType
    };

    // 如果 entryPageType 为 'lite'，则添加 entryLiteType 信息
    if (data.entryPageType === 'lite') {
      channelExtra.entryLiteType = data.entryLiteType || 'wxapp'; // 默认值可以根据业务需求修改
    }

    // 如果有其他的附加属性，如 payDataType
    if (data.payDataType) {
      channelExtra.payDataType = data.payDataType;
    }

    if (data.wayCode === 'QR_CASHIER' && (data.entryPageType === 'h5' || data.entryPageType === 'lite')) {
      channelExtra.payDataType = 'codeImgUrl'
    }

    if (data.authCode) {
      channelExtra.authCode = data.authCode
    }

    console.log("======================")
    console.log(data);
    console.log(channelExtra);

    // 构建 Berapay 请求参数
    const params = constructBerapayRequest(
      data.wayCode, // 支付方式
      data.mchOrderNo, // 商户订单号
      data.amount, // 支付金额
      data.subject, // 商品标题
      false, // 是否自动分账，根据业务需求设置
      channelExtra // 将构建的 channelExtra 对象传入
    );

    try {
      // 发送支付请求
      const response = await sendBerapayRequest(params);

      // 处理支付响应
      return handleBerapayResponse(
        response,
        (url) => {
          // 设置二维码 URL 函数，显示支付二维码
          console.log("显示二维码的 URL: ", url);
        },
        (title) => {
          // 设置二维码模态框的标题
          console.log("二维码模态框标题: ", title);
        },
        (visible) => {
          // 显示/隐藏二维码模态框
          console.log("显示二维码模态框: ", visible);
        },
        () => {
          // 支付状态轮询函数
          console.log("开始支付状态轮询");
        }
      );
    } catch (error) {
      console.error(error);
      throw new Error('支付接口调用失败');
    }
  }

  // 处理其他支付方式
  return postRequest('/test/pay/createOrder', data).then((response) => response.data as PayOrderResult2);
};

// 获取支付数据列表
export const fetchPaymentData1 = (page: number = 1, limit: number = 10): Promise<PayOrderResponse> => {
  if (USE_MOCK_DATA || true) {
    return mockFetchPaymentData(page, limit);
  }
  return getRequest(`/api/pay/list?page=${page}&limit=${limit}`).then((response) => response.data as PayOrderResponse);
};