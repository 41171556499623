/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // 导入 useLocation 钩子
import { Button, Descriptions, message, Spin } from 'antd';
import { fetchUserDetail } from './apiUser'; // 假设你有获取User详情的 API
import type { User } from './User';

const UserDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // 从 URL 中获取 ID
  const navigate = useNavigate(); // 使用 useNavigate
  const location = useLocation(); // 获取当前路径信息
  const [data, setData] = useState<User | null>(null); // 存储详情
  const [loading, setLoading] = useState(false); // 加载状态

  // 判断是否为管理员路径
  const isAdminPath = location.pathname.includes('/admin');

  // 获取详情
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchUserDetail(Number(id)); // 调用封装的 API 获取详情
        if (response.data) {
          setData(response.data);
        } else {
          message.error('未找到相关信息');
        }
      } catch (error) {
        console.error('Error fetching details:', error);
        message.error('无法获取详情');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // 返回列表
  const handleBack = () => {
    const listPath = isAdminPath ? '/test/admin/user-list' : '/test/user-list';
    navigate(listPath); // 根据当前路径跳转到不同的列表页
  };
  // 如果正在加载，显示 Spin 组件
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin tip="" size="large">
          <div style={{ height: '100px' }}></div> {/* 子组件内容作为 Spin 的嵌套内容 */}
        </Spin>
      </div>
    );
  }

  // 如果未找到信息，显示提示
  if (!data) {
    return <p>未找到详情信息</p>;
  }

  // 正常渲染详情
  return (
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-bold mb-4">User - 详情</h2>
      <Descriptions bordered>
        <Descriptions.Item label="用户ID">
            {/*
              默认显示字段值
            */}
            { data.sysUserId }
        </Descriptions.Item>
        <Descriptions.Item label="登录用户名">
            {/*
              默认显示字段值
            */}
            { data.loginUsername }
        </Descriptions.Item>
        <Descriptions.Item label="姓名">
            {/*
              默认显示字段值
            */}
            { data.realname }
        </Descriptions.Item>
        <Descriptions.Item label="手机号">
            {/*
              默认显示字段值
            */}
            { data.telephone }
        </Descriptions.Item>
        <Descriptions.Item label="性别">
            {/*
              如果存在 value_map，则根据映射关系显示相应的值
            */}
            {
              (() => {
                switch (data.sex) {
                  case 1:
                    return '男';
                  case 2:
                    return '女';
                  default:
                    return '未知';
                }
              })()
            }
        </Descriptions.Item>
        <Descriptions.Item label="头像地址">
            {/*
              如果字段类型是图片，则渲染图片
            */}
            <img src={data.avatarUrl ?? ''} alt="图片" style={{ width: '100px', height: '100px' }} />
        </Descriptions.Item>
        <Descriptions.Item label="员工编号">
            {/*
              默认显示字段值
            */}
            { data.userNo }
        </Descriptions.Item>
        <Descriptions.Item label="邀请码">
            {/*
              默认显示字段值
            */}
            { data.inviteCode }
        </Descriptions.Item>
        <Descriptions.Item label="用户类型">
            {/*
              如果存在 value_map，则根据映射关系显示相应的值
            */}
            {
              (() => {
                switch (data.userType) {
                  case 1:
                    return '超级管理员';
                  case 2:
                    return '普通用户';
                  case 3:
                    return '拓展员';
                  case 11:
                    return '店长';
                  case 12:
                    return '店员';
                  default:
                    return '未知';
                }
              })()
            }
        </Descriptions.Item>
        <Descriptions.Item label="团队ID">
            {/*
              默认显示字段值
            */}
            { data.teamId }
        </Descriptions.Item>
        <Descriptions.Item label="是否队长">
            {/*
              如果存在 value_map，则根据映射关系显示相应的值
            */}
            {
              (() => {
                switch (data.isTeamLeader) {
                  case 1:
                    return '是';
                  case 0:
                    return '否';
                  default:
                    return '未知';
                }
              })()
            }
        </Descriptions.Item>
        <Descriptions.Item label="状态">
            {/*
              如果存在 value_map，则根据映射关系显示相应的值
            */}
            {
              (() => {
                switch (data.state) {
                  case 1:
                    return '启用';
                  case 0:
                    return '停用';
                  default:
                    return '未知';
                }
              })()
            }
        </Descriptions.Item>
        <Descriptions.Item label="所属系统">
            {/*
              默认显示字段值
            */}
            { data.sysType }
        </Descriptions.Item>
        <Descriptions.Item label="所属商户ID">
            {/*
              默认显示字段值
            */}
            { data.belongInfoId }
        </Descriptions.Item>
        <Descriptions.Item label="MFA绑定状态">
            {/*
              如果存在 value_map，则根据映射关系显示相应的值
            */}
            {
              (() => {
                switch (data.mfaBindState) {
                  case 0:
                    return '未绑定';
                  case 1:
                    return '已绑定';
                  default:
                    return '未知';
                }
              })()
            }
        </Descriptions.Item>
        <Descriptions.Item label="MFA验证秘钥">
            {/*
              默认显示字段值
            */}
            { data.mfaSecretKey }
        </Descriptions.Item>
        <Descriptions.Item label="安全预留信息">
            {/*
              默认显示字段值
            */}
            { data.safeWord }
        </Descriptions.Item>
        <Descriptions.Item label="密码过期时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.pwdExpiredTime).toLocaleString() }
        </Descriptions.Item>
        <Descriptions.Item label="最后一次重置密码时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.lastResetPwdTime).toLocaleString() }
        </Descriptions.Item>
        <Descriptions.Item label="上次登录时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.lastLoginTime).toLocaleString() }
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.createdAt).toLocaleString() }
        </Descriptions.Item>
        <Descriptions.Item label="更新时间">
            {/*
              如果字段类型是 datetime，则格式化日期
            */}
            { new Date(data.updatedAt).toLocaleString() }
        </Descriptions.Item>
      </Descriptions>
      <Button type="primary" onClick={handleBack} className="mt-4">
        返回列表
      </Button>
    </div>
  );
};

export default UserDetailPage;
