/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/request';
import {
  getUserAuthMockData,
  mockAddUserAuth,
  mockEditUserAuth,
  mockDeleteUserAuth,
  mockFetchUserAuthDetail
} from './mockUserAuthData'; // 导入 Mock 函数

const USE_MOCK_DATA = true; // 切换 Mock 数据标志

// 获取UserAuth列表的请求
export const fetchUserAuthList = (page: number, pageSize: number) => {
  if (USE_MOCK_DATA) {
    return getUserAuthMockData(page, pageSize);
  }
  return getRequest('/api/v1/test/userauth/page', {
    page,
    size: pageSize,
  });
};

// 添加新UserAuth的请求
export const addUserAuth = (data: any) => {
  if (USE_MOCK_DATA) {
    return mockAddUserAuth(data);
  }
  return postRequest('/api/v1/test/userauth', data);
};

// 编辑UserAuth的请求
export const editUserAuth = (id: number, data: any) => {
  if (USE_MOCK_DATA) {
    return mockEditUserAuth(id, data);
  }
  return putRequest(`/api/v1/test/userauth/${id}`, data);
};

// 删除UserAuth的请求
export const deleteUserAuth = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockDeleteUserAuth(id);
  }
  return deleteRequest(`/api/v1/test/userauth/${id}`);
};

// 获取UserAuth详情的请求
export const fetchUserAuthDetail = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockFetchUserAuthDetail(id);
  }
  return getRequest(`/api/v1/test/userauth/${id}`);
};
