/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

// route/routeBook.ts
import { RouteObject, useNavigate } from 'react-router-dom';
import BookListPage from '../../logic/auto/book/BookListPage';
import BookDetailPage from '../../logic/auto/book/BookDetailPage';
import { Button } from 'antd';

const bookRoutes: RouteObject[] = [
  { path: '/test/book-list', element: <BookListPage /> },
  { path: '/test/book-detail/:id', element: <BookDetailPage /> },
];

const bookAdminRoutes: RouteObject[] = [
  { path: '/test/admin/book-list', element: <BookListPage /> },
  { path: '/test/admin/book-detail/:id', element: <BookDetailPage /> },
];

// 测试入口组件
export const TestEntry: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button type="default" size="large" onClick={() => navigate('/test/book-list')}>
      BookList Test
    </Button>
  );
};

// 测试入口menu
export const menuData: {} = {
  key : "/test/admin/book-list",
  path: "/test/admin/book-list",
  name: "Book管理"
}

export default { routes: bookRoutes, adminRoutes: bookAdminRoutes, TestEntry, menuData };
