export function generateOrderNumber(): string {
    return (
      "M" +
      new Date().getTime() +
      Math.floor(Math.random() * (9999 - 1000) + 1000)
    );
  }

export function generateRefundNo(): string {
    return (
      "R" +
      new Date().getTime() +
      Math.floor(Math.random() * (9999 - 1000) + 1000)
    );
  }

 export  function generateRandomAmounts(): any[] {
    let amounts: any[] = [];
    for (let i = 0; i < 4; i++) {
      amounts.push((Math.random() * (1 - 0.1) + 0.1).toFixed(2));
    }
    return amounts;
  }