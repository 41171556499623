/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import Mock, { generateMockTemplate } from '../../manual/mock';

// 定义规则映射（配置文件）
const rulesMap: any = {

  configKey: '@name', // 配置KEY
  configName: '@name', // 配置名称
  configDesc: '@paragraph(1, 3)', // 描述信息
  groupKey: '@name', // 分组KEY
  groupName: '@name', // 分组名称
  configVal: '@name', // 配置内容项
  type: '@pick(["text", "textarea", "switch"])', // 类型: text-输入框, textarea-多行文本, uploadImg-上传图片, switch-开关
  sortNum: '@natural(1, 100)', // 显示顺序
  createdAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 创建时间
  updatedAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 更新时间
};

// 生成 mock 数据模板
const configTemplate: any = generateMockTemplate(rulesMap);

// 生成 mock 数据
let mockConfigData = Mock.mock({
  'configs|100': [configTemplate] // 生成100条数据
}).configs;

// 获取Config列表（模拟的分页）
export const getConfigMockData = (page: number, pageSize: number): Promise<any> => {
  const start = (page - 1) * pageSize;
  const records = mockConfigData.slice(start, start + pageSize);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        errorCode: 0,
        message: 'Request processed successfully',
        data: {
          records,
          total: mockConfigData.length,
          size: pageSize,
          current: page,
          pages: Math.ceil(mockConfigData.length / pageSize),
        },
      });
    }, 50);
  });
};

// 模拟添加Config
export const mockAddConfig = (newConfig: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockConfigData.push({
      ...newConfig,
      configKey: mockConfigData.length + 1,
      createdAt: now,
      updatedAt: now
    });
    setTimeout(() => resolve({ errorCode: 0, message: 'Config added successfully' }), 50);
  });
};

// 模拟编辑Config
export const mockEditConfig = (id: string, updatedConfig: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockConfigData = mockConfigData.map((item:any) =>
      (item.configKey === id ? { ...item, ...updatedConfig, updatedAt: now } : item)
    );
    setTimeout(() => resolve({ errorCode: 0, message: 'Config updated successfully' }), 50);
  });
};

// 模拟删除Config
export const mockDeleteConfig = (id: string): Promise<any> => {
  return new Promise((resolve) => {
    mockConfigData = mockConfigData.filter((item:any) => item.configKey !== id);
    setTimeout(() => resolve({ errorCode: 0, message: 'Config deleted successfully' }), 50);
  });
};

// 获取Config详情（模拟）
export const mockFetchConfigDetail = (id: string): Promise<any> => {
  const item = mockConfigData.find((item:any) => item.configKey === id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (item) {
        resolve({ data: item }); // 返回找到的Config
      } else {
        reject(new Error('Config not found')); // 未找到Config时返回错误
      }
    }, 50); // 模拟延迟50ms
  });
};
