/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */


import React from 'react';
import { Table, Button, message, Space } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import { tableColumnsArticle } from './tableColumnsArticle'; // 从独立文件中导入表格列定义
import ArticleAddOrEditModal from './ArticleAddOrEditModal'; // 用于添加和编辑用户的表单 Modal 组件
import { Article } from './Article';
import { fetchArticleList, addArticle, editArticle, deleteArticle } from './apiArticle'; // 导入封装的 API
import { useNavigate, useLocation } from 'react-router-dom';

const ArticleListPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 获取当前路径信息
  const [data, setData] = React.useState<Article[]>([]);
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [editingArticle, setEditingArticle] = React.useState<Article | null>(null); // 用于编辑的Article

  // 判断是否为管理员路径
  const isAdminPath = location.pathname.includes('/admin');

  // 请求数据函数，自动切换 mock 数据或真实数据
  const fetchTableData = async (page: number = 1, pageSize: number = 10) => {
    setLoading(true);
    try {
      const response = await fetchArticleList(page, pageSize);
      setData(response.data.records);
      setPagination({
        current: response.data.current,
        pageSize: response.data.size,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载数据
  React.useEffect(() => {
    fetchTableData();
  }, []);

  // 显示 Modal 表单
  const showAddArticleModal = () => {
    setEditingArticle(null); // 添加时不编辑任何Article
    setIsModalVisible(true);
  };

  // 关闭 Modal 表单
  const handleCancel = () => {
    setIsModalVisible(false);
    setTimeout(() => {
       setEditingArticle(null); // Modal 关闭后重置编辑状态
    }, 100);
  };

  // 提交表单，添加或编辑Article
  const handleAddOrEditArticle = async (values: any) => {
    try {
      if (editingArticle) {
        // 编辑模式
        await editArticle(editingArticle.id, values); // 调用封装的 editArticle API
        const updatedData = data.map((item) =>
          item.id === editingArticle.id ? { ...editingArticle, ...values } : item
        );
        setData(updatedData);
        message.success('Article信息更新成功');
      } else {
        // 添加模式
        const newArticle: Article = {
          id: data.length + 1, // 模拟自增ID
          ...values,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        await addArticle(newArticle); // 调用封装的 addArticle API
        setData([...data, newArticle]); // 添加新Article到表格
        message.success('Article添加成功');
      }
    } catch (error) {
      console.error('Error adding or editing Article:', error);
    } finally {
      setIsModalVisible(false); // 关闭Modal
    }
  };

  // 查看按钮点击处理函数
  const handleView = (record: Article) => {
    console.log('查看Article：', record);
    const detailPath = isAdminPath ? '/test/admin/article-detail' : '/test/article-detail';
    navigate(`${detailPath}/${record.id}`); // 根据当前路径跳转到不同的详情页
  };

  // 编辑Article
  const handleEdit = (record: Article) => {
    setEditingArticle(record); // 设置当前编辑的Article
    setIsModalVisible(true);
  };

  // 删除Article
  const handleDelete = async (id: number) => {
    try {
      await deleteArticle(id); // 调用封装的 deleteArticle API
      const updatedData = data.filter((item) => item.id !== id);
      setData(updatedData); // 更新表格数据
      message.success('Article删除成功');
    } catch (error) {
      console.error('Error deleting Article:', error);
    }
  };

  // 处理分页改变
  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchTableData(pagination.current, pagination.pageSize);
  };

  // 扩展表格列，添加操作按钮
  const extendedColumns = [
    ...tableColumnsArticle,
    {
      title: '操作',
      key: 'operate',
      render: (text: any, record: Article) => (
        <div style={{ textAlign: 'left' }}>
          <Space>
            <Button type="default" onClick={() => handleView(record)} style={{ marginRight: 8 }}>
              查看
            </Button>
            <Button type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>
              修改
            </Button>
            <Button type="primary" danger onClick={() => handleDelete(record.id)}>
              删除
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6">
      {!isAdminPath && (
        <h2 className="text-1xl font-bold text-center mb-4">Article Management</h2>
      )}
      <Button type="primary" onClick={showAddArticleModal} className="mb-4">
        添加Article
      </Button>
      <Table
        columns={extendedColumns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <ArticleAddOrEditModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleAddOrEditArticle}
        initialValues={editingArticle}
        key={editingArticle ? editingArticle.id : 'add-article'} // 强制刷新
      />
    </div>
  );
};

export default ArticleListPage;
