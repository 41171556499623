/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

// route/routeUserAuth.ts
import { RouteObject, useNavigate } from 'react-router-dom';
import UserAuthListPage from '../../logic/auto/userauth/UserAuthListPage';
import UserAuthDetailPage from '../../logic/auto/userauth/UserAuthDetailPage';
import { Button } from 'antd';

const userauthRoutes: RouteObject[] = [
  { path: '/test/userauth-list', element: <UserAuthListPage /> },
  { path: '/test/userauth-detail/:id', element: <UserAuthDetailPage /> },
];

const userauthAdminRoutes: RouteObject[] = [
  { path: '/test/admin/userauth-list', element: <UserAuthListPage /> },
  { path: '/test/admin/userauth-detail/:id', element: <UserAuthDetailPage /> },
];

// 测试入口组件
export const TestEntry: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button type="default" size="large" onClick={() => navigate('/test/userauth-list')}>
      UserAuthList Test
    </Button>
  );
};

// 测试入口menu
export const menuData: {} = {
  key : "/test/admin/userauth-list",
  path: "/test/admin/userauth-list",
  name: "UserAuth管理"
}

export default { routes: userauthRoutes, adminRoutes: userauthAdminRoutes, TestEntry, menuData };
