// route/routeDomain.ts
import { RouteObject, useNavigate } from 'react-router-dom';
import DomainListPage from '../../logic/manual/domain/DomainListPage';
import DomainDetailPage from '../../logic/manual/domain/DomainDetailPage';
import { Button, Col } from 'antd';

const domainRoutes: RouteObject[] = [
  { path: '/test/domain-list', element: <DomainListPage /> },
  { path: '/test/domain-detail/:id', element: <DomainDetailPage /> },
];

export const TestEntry: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Button type="default" size="large" onClick={() => navigate('/test/domain-list')}>
        DomainList Test
        </Button>
    );
  };

export default {routes: domainRoutes, testEntry: TestEntry};
