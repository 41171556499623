import React, { useState } from 'react';
import { Button, Drawer, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const DrawerTestPage: React.FC = () => {
  const [visible, setVisible] = useState(false);

  // 打开抽屉
  const showDrawer = () => {
    setVisible(true);
  };

  // 关闭抽屉
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div style={{ padding: '40px', textAlign: 'center' }}>
      <Title level={2}>Drawer 测试页面</Title>
      <Paragraph>点击按钮打开抽屉。</Paragraph>
      <Button type="primary" onClick={showDrawer}>
        打开抽屉
      </Button>
      
      <Drawer
        title="测试抽屉"
        placement="right"
        closable={true}
        onClose={onClose}
        open={visible}
        width={400}
      >
        <p>这是一个抽屉组件的示例内容。</p>
        <p>你可以在这里放置任何需要展示的内容。</p>
        <p>还可以设置不同的宽度、位置等。</p>
      </Drawer>
    </div>
  );
};

export default DrawerTestPage;