/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, DatePicker, Checkbox, Radio } from 'antd';

interface BookAddOrEditModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues?: any; // 支持传递初始值
}

const BookAddOrEditModal: React.FC<BookAddOrEditModalProps> = ({ visible, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm();

  // 当 initialValues 发生变化时，重置表单的初始值，确保所有字段都设置初始值
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  // 表单提交时，将未显示在表单中的字段也透传到服务器
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const combinedValues = { ...initialValues, ...values }; // 合并表单数据和初始数据
        onOk(combinedValues); // 将完整数据传递给父组件
        form.resetFields(); // 重置表单
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="添加/编辑书籍表"
      open={visible}
      onCancel={() => {
        form.resetFields(); // 重置表单
        onCancel(); // 调用取消处理函数
      }}
      onOk={handleOk} // 在表单提交时调用 handleOk 函数
    >
      <Form form={form} layout="vertical" name="addOrEditBookForm" initialValues={initialValues}>
        <Form.Item
          name="title"
          label="名称"
          rules={[
            { required: true, message: '请输入名称' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="author"
          label="作者"
          rules={[
            { required: false, message: '请输入作者' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="书籍简介"
          rules={[
            { required: false, message: '请输入书籍简介' }
          ]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          name="category"
          label="书籍分类"
          rules={[
            { required: false, message: '请输入书籍分类' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="isbn"
          label="ISBN"
          rules={[
            { required: false, message: '请输入ISBN' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tags"
          label="书籍标签"
          rules={[
            { required: false, message: '请输入书籍标签' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="imageUrl"
          label="封面"
          rules={[
            { required: false, message: '请输入封面' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="publishedDate"
          label="出版日期"
          rules={[
            { required: false, message: '请输入出版日期' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label="书籍价格"
          rules={[
            { required: false, message: '请输入书籍价格' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BookAddOrEditModal;
