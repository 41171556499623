import React, { useState } from 'react';

const Home: React.FC = () => {
  const [isAdvancedSettingsVisible, setIsAdvancedSettingsVisible] = useState(false);

  const toggleAdvancedSettings = () => {
    setIsAdvancedSettingsVisible(!isAdvancedSettingsVisible);
  };

  return (
    <main className="flex-grow mt-6 mb-6">
      <div id="content-wrapper" className="max-w-7xl mx-auto flex flex-col md:flex-row md:space-x-2 px-4 h-full">
        <div id="form-container" className="w-full md:w-3/4 bg-yellow-100">
          <div className="bg-white shadow-md rounded-lg p-6 border h-full">
            <form id="qrForm" className="space-y-4">
              <div>
                <textarea
                  id="text"
                  name="text"
                  className="mt-1 block w-full px-3 py-2 h-32 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="请输入文字"
                ></textarea>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-full md:w-1/3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  生成二维码
                </button>
              </div>
              <div className="flex justify-center mt-4">
                <button
                  type="button"
                  id="advancedSettingsToggle"
                  className="text-blue-500 underline"
                  onClick={toggleAdvancedSettings}
                >
                  选项
                </button>
              </div>
              <div id="advancedSettings" className={`space-y-4 mt-4 ${isAdvancedSettingsVisible ? '' : 'hidden'}`}>
                <div className="flex flex-wrap -mx-2">
                  <div className="w-1/2 px-2 mb-4">
                    <label htmlFor="length" className="block text-sm font-medium text-gray-700">长度</label>
                    <input
                      id="length"
                      name="length"
                      type="number"
                      defaultValue="400"
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="w-1/2 px-2 mb-4">
                    <label htmlFor="margin" className="block text-sm font-medium text-gray-700">内边距</label>
                    <input
                      id="margin"
                      name="margin"
                      type="number"
                      defaultValue="1"
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="w-1/2 px-2 mb-4">
                    <label htmlFor="foregroundColor" className="block text-sm font-medium text-gray-700">前景色</label>
                    <input
                      id="foregroundColor"
                      name="foregroundColor"
                      type="color"
                      defaultValue="#000000"
                      className="mt-1 block w-full px-1 py-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="w-1/2 px-2 mb-4">
                    <label htmlFor="backgroundColor" className="block text-sm font-medium text-gray-700">背景色</label>
                    <input
                      id="backgroundColor"
                      name="backgroundColor"
                      type="color"
                      defaultValue="#FFFFFF"
                      className="mt-1 block w-full px-1 py-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="w-1/2 px-2 mb-4">
                    <label htmlFor="errorCorrectionLevel" className="block text-sm font-medium text-gray-700">容错率</label>
                    <select
                      id="errorCorrectionLevel"
                      name="errorCorrectionLevel"
                      defaultValue="H"
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="L">L (低)</option>
                      <option value="M">M (中)</option>
                      <option value="Q">Q (高)</option>
                      <option value="H">H (非常高)</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div id="preview-container" className="w-full md:w-1/4 flex flex-col items-center justify-start ml-10 md:ml-0 h-300">
          <div className="bg-white shadow-md rounded-lg p-6 border h-full min-w-full flex flex-col items-center">
            <h2 className="text-lg font-semibold text-center">二维码预览</h2>
            <div className="flex justify-center items-center w-full h-full">
              <img
                id="qrImage"
                src="https://placeholder.pics/svg/200x200"
                alt="二维码预览"
                className="mt-4 border border-gray-300 rounded-lg"
                style={{ width: 200, height: 200 }}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;