import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const errorMessages: { [key: number]: string } = {
  400: '错误的请求',
  401: '未经授权',
  402: '需要支付',
  403: '禁止访问',
  404: '页面未找到',
  405: '方法不允许',
  408: '请求超时',
  429: '请求过多',
  500: '服务器错误',
};

const ErrorPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const errorCode = code ? parseInt(code, 10) : 404;
  const message = errorMessages[errorCode] || '未知错误';

  return (
    <div className="flex-grow flex flex-col items-center justify-center bg-soft-main text-center p-6">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8">
        <ExclamationCircleOutlined className="text-red-500 text-6xl mb-4" />
        <h1 className="text-4xl font-bold mb-4">{errorCode} - {message}</h1>
        <p className="text-lg mb-6">抱歉，发生了一个错误。请点击下面的按钮返回首页。</p>
        <Link to="/" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded">
          返回首页
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;