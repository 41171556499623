import axios from 'axios';
import { message } from 'antd';

// 创建 Axios 实例
const service = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // 基础 URL，可以根据环境变量配置
  timeout: 5000, // 超时时间
});

// 请求拦截器，可以在这里处理请求前的逻辑
service.interceptors.request.use(
  (config) => {
    // 例如，可以在这里添加认证 token 等操作
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器，可以在这里处理响应后的逻辑
service.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.errorCode !== 0) {
      message.error(data.message || '请求失败');
      return Promise.reject(new Error(data.message || '请求失败'));
    }
    return data;
  },
  (error) => {
    // 响应错误处理
    message.error(error.message || '网络错误');
    return Promise.reject(error);
  }
);

// 通用的 GET 请求
export const getRequest = (url: string, params?: any) => {
  return service.get(url, { params });
};

// 通用的 POST 请求
export const postRequest = (url: string, data?: any) => {
  return service.post(url, data);
};

// 通用的 DELETE 请求
export const deleteRequest = (url: string, params?: any) => {
    return service.delete(url, { params });
  };

// 通用的 PUT 请求
export const putRequest = (url: string, data?: any) => {
    return service.put(url, data);
};
