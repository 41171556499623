import { Tag } from "antd";
import { ColumnsType } from "antd/es/table";

export const tableColumnsOrder: ColumnsType<any> = [
    {
      title: "订单编号",
      dataIndex: "orderNo",
      key: "orderNo",
    },
    {
      title: "支付金额",
      dataIndex: "amount",
      key: "amount",
      render: (text: any) => (text / 100).toFixed(2),
    },
    {
      title: "支付方式",
      dataIndex: "payType",
      key: "payType",
      render: (text: any) => {
        const payTypeMap: any = {
          WX_NATIVE: "微信二维码",
          WX_JSAPI: "公众号/小程序",
          WX_BAR: "微信条码",
          WX_H5: "微信H5",
          ALI_QR: "支付宝二维码",
          ALI_JSAPI: "支付宝生活号",
          ALI_BAR: "支付宝条码",
          ALI_PC: "支付宝PC网站",
          ALI_WAP: "支付宝WAP",
          QR_CASHIER: "聚合扫码",
          AUTO_BAR: "聚合条码",
          WEB_CASHIER: "web收银台",
        };
        return payTypeMap[text] || text;
      },
    },
    {
      title: "支付状态",
      dataIndex: "status",
      key: "status",
      render: (text: any) => {
        const statusMap: any = {
          0: <Tag color="blue">订单生成</Tag>,
          1: <Tag color="orange">待支付</Tag>,
          2: <Tag color="green">支付成功</Tag>,
          3: <Tag>支付失败</Tag>,
          4: <Tag color="cyan">已取消</Tag>,
          5: <Tag color="black">已退款</Tag>,
        };
        return statusMap[text] || text;
      },
    },
    {
      title: "客户IP",
      dataIndex: "clientIp",
      key: "clientIp",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      render: (text: any) => new Date(text).toLocaleString(),
    },
    // {
    //   title: "操作",
    //   key: "action",
    //   render: (text: any, record: any) => (
    //     <Button
    //       type="link"
    //       disabled={!record.userId || record.status !== 2}
    //       onClick={() => handleRefund(record)}
    //     >
    //       退款
    //     </Button>
    //   ),
    // },
  ];
