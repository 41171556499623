import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, message, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './AdminLoginPage.css'; // 引入样式文件

const AdminLoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    setLoading(true);
    // 模拟登录请求
    setTimeout(() => {
      setLoading(false);
      if (values.username === 'admin' && values.password === 'admin') {
        message.success('登录成功！');
        navigate('/dashboard'); // 跳转到后台首页
      } else {
        message.error('用户名或密码错误');
      }
    }, 1000);
  };

  return (
    <div className="login-page">
      {/* 背景图片 */}
      <div className="login-background"></div>

      {/* 登录表单卡片 */}
      <div className="login-container">
        <Card className="login-card">
          <h2 className="text-center mb-6">登录</h2>
          <Form
            name="login_form"
            layout="vertical"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: '请输入用户名!' }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="请输入用户名"
                autoComplete="username"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="请输入密码"
                autoComplete="current-password"
              />
            </Form.Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>自动登录</Checkbox>
              </Form.Item>
              <a className="login-form-forgot" href="#" style={{ float: 'right' }}>
                忘记密码？
              </a>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                block
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>

      {/* 页脚 */}
      <footer className="login-footer">
        <p>
          Copyright © 2017 - 2024 海内收银. All Rights
          Reserved. 火金科技 版权所有
          <br />
          <br />
          {/* 添加图标 */}
          <img
            src="https://mgr.berapay.cn/assets/icon-icp.9a71ea93.png"
            alt="ICP备案图标"
            className="icp-icon"
          />
          ICP备案：沪ICP备18015888号-3
        </p>
      </footer>
    </div>
  );
};

export default AdminLoginPage;