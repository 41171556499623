/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, DatePicker, Checkbox, Radio } from 'antd';

interface ArticleAddOrEditModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
  initialValues?: any; // 支持传递初始值
}

const ArticleAddOrEditModal: React.FC<ArticleAddOrEditModalProps> = ({ visible, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm();

  // 当 initialValues 发生变化时，重置表单的初始值，确保所有字段都设置初始值
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  // 表单提交时，将未显示在表单中的字段也透传到服务器
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const combinedValues = { ...initialValues, ...values }; // 合并表单数据和初始数据
        onOk(combinedValues); // 将完整数据传递给父组件
        form.resetFields(); // 重置表单
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="添加/编辑文章信息表"
      open={visible}
      onCancel={() => {
        form.resetFields(); // 重置表单
        onCancel(); // 调用取消处理函数
      }}
      onOk={handleOk} // 在表单提交时调用 handleOk 函数
    >
      <Form form={form} layout="vertical" name="addOrEditArticleForm" initialValues={initialValues}>
        <Form.Item
          name="storeId"
          label="门店ID"
          rules={[
            { required: true, message: '请输入门店ID' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          name="merchantId"
          label="商户ID"
          rules={[
            { required: false, message: '请输入商户ID' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          name="title"
          label="文章标题"
          rules={[
            { required: false, message: '请输入文章标题' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="brief"
          label="文章简介"
          rules={[
            { required: false, message: '请输入文章简介' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="url"
          label="链接地址"
          rules={[
            { required: false, message: '请输入链接地址' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="image"
          label="图片地址"
          rules={[
            { required: false, message: '请输入图片地址' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="描述"
          rules={[
            { required: false, message: '请输入描述' }
          ]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          name="click"
          label="点击次数"
          rules={[
            { required: false, message: '请输入点击次数' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          name="operator"
          label="最后操作人"
          rules={[
            { required: false, message: '请输入最后操作人' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sort"
          label="排序"
          rules={[
            { required: false, message: '请输入排序' }
          ]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          name="status"
          label="状态"
          rules={[
            { required: false, message: '请输入状态' }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ArticleAddOrEditModal;
