import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from './layout/MainLayout';
import AdminLoginLayout from './layout/AdminLoginLayout';
import Login from './page/Login';
import Register from './page/Register';
import ErrorPage from './page/ErrorPage';
import Home from './page/Home';
import Templates from './page/Templates';
import routes, {adminRoutes} from './route';
import { renderRoutes as renderAutoGeneratedRoutes } from './route/entry';
import TestIndexPage from './page/test/TestIndexPage';
import CashierTestPage2 from './page/test/CashierTestPage2';
import CashierTestPage from './page/test/CashierTestPage';
import DrawerTestPage from './page/test/DrawerTestPage';
import ImageTestPage from './page/test/ImageTestPage';
import PickerTestPage from './page/test/PickerTestPage';
import AdminLoginPage from './page/login/AdminLoginPage';
import AdminLayout from './layout/AdminLayout';
import DashboardPage from './component/DashboardPage';

const App: React.FC = () => {
  return (
    <Routes>
      {/* 使用 MainLayout 包装的路由 */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        {renderAutoGeneratedRoutes(routes)}
        <Route path="/test/picker-test" element={<PickerTestPage />} />
        <Route path="/test/image-test" element={<ImageTestPage />} />
        <Route path="/test/drawer-test" element={<DrawerTestPage />} />
        <Route path="/test/cashier-test" element={<CashierTestPage />} />
        <Route path="/test/cashier-test2" element={<CashierTestPage2 />} />
        <Route path="/test/index" element={<TestIndexPage />} />
        <Route path="/test" element={<TestIndexPage />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/error/:code" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>

      {/* 使用 AdminLoginLayout 包装的路由 */}
      <Route element={<AdminLoginLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/test/admin-login" element={<AdminLoginPage />} />
      </Route>

      {/* AdminLayout 包装的路由 */}
      <Route element={<AdminLayout />}>
        <Route path="/test/dashboard" element={<DashboardPage />} />
        {renderAutoGeneratedRoutes(adminRoutes)}
      </Route>
    </Routes>
  );
};

export default App;