/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import type { ColumnsType } from 'antd/es/table';
import { User } from './User';

// 生成 User 表格列配置
export const tableColumnsUser: ColumnsType<User> = [
  {
    title: '用户ID',
    dataIndex: 'sysUserId',
    key: 'sysUserId',
  },
  {
    title: '登录用户名',
    dataIndex: 'loginUsername',
    key: 'loginUsername',
  },
  {
    title: '姓名',
    dataIndex: 'realname',
    key: 'realname',
  },
  {
    title: '手机号',
    dataIndex: 'telephone',
    key: 'telephone',
  },
  {
    title: '性别',
    dataIndex: 'sex',
    key: 'sex',
    // 使用 value_map 映射值
    render: (value: number) => {
      switch (value) {
        case 1:
          return '男';
        case 2:
          return '女';
        default:
          return '未知类型';
      }
    },
  },
  {
    title: '头像地址',
    dataIndex: 'avatarUrl',
    key: 'avatarUrl',
    render: (url: string) => <img src={url} alt="图片" style={{ width: '50px' }} />,
  },
  {
    title: '用户类型',
    dataIndex: 'userType',
    key: 'userType',
    // 使用 value_map 映射值
    render: (value: number) => {
      switch (value) {
        case 1:
          return '超级管理员';
        case 2:
          return '普通用户';
        case 3:
          return '拓展员';
        case 11:
          return '店长';
        case 12:
          return '店员';
        default:
          return '未知类型';
      }
    },
  },
  {
    title: '所属系统',
    dataIndex: 'sysType',
    key: 'sysType',
  },
  {
    title: '所属商户ID',
    dataIndex: 'belongInfoId',
    key: 'belongInfoId',
  }
];
