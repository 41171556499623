/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */


import React from 'react';
import { Table, Button, message, Space } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import { tableColumnsBook } from './tableColumnsBook'; // 从独立文件中导入表格列定义
import BookAddOrEditModal from './BookAddOrEditModal'; // 用于添加和编辑用户的表单 Modal 组件
import { Book } from './Book';
import { fetchBookList, addBook, editBook, deleteBook } from './apiBook'; // 导入封装的 API
import { useNavigate, useLocation } from 'react-router-dom';

const BookListPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 获取当前路径信息
  const [data, setData] = React.useState<Book[]>([]);
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [editingBook, setEditingBook] = React.useState<Book | null>(null); // 用于编辑的Book

  // 判断是否为管理员路径
  const isAdminPath = location.pathname.includes('/admin');

  // 请求数据函数，自动切换 mock 数据或真实数据
  const fetchTableData = async (page: number = 1, pageSize: number = 10) => {
    setLoading(true);
    try {
      const response = await fetchBookList(page, pageSize);
      setData(response.data.records);
      setPagination({
        current: response.data.current,
        pageSize: response.data.size,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载数据
  React.useEffect(() => {
    fetchTableData();
  }, []);

  // 显示 Modal 表单
  const showAddBookModal = () => {
    setEditingBook(null); // 添加时不编辑任何Book
    setIsModalVisible(true);
  };

  // 关闭 Modal 表单
  const handleCancel = () => {
    setIsModalVisible(false);
    setTimeout(() => {
       setEditingBook(null); // Modal 关闭后重置编辑状态
    }, 100);
  };

  // 提交表单，添加或编辑Book
  const handleAddOrEditBook = async (values: any) => {
    try {
      if (editingBook) {
        // 编辑模式
        await editBook(editingBook.id, values); // 调用封装的 editBook API
        const updatedData = data.map((item) =>
          item.id === editingBook.id ? { ...editingBook, ...values } : item
        );
        setData(updatedData);
        message.success('Book信息更新成功');
      } else {
        // 添加模式
        const newBook: Book = {
          id: data.length + 1, // 模拟自增ID
          ...values,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        await addBook(newBook); // 调用封装的 addBook API
        setData([...data, newBook]); // 添加新Book到表格
        message.success('Book添加成功');
      }
    } catch (error) {
      console.error('Error adding or editing Book:', error);
    } finally {
      setIsModalVisible(false); // 关闭Modal
    }
  };

  // 查看按钮点击处理函数
  const handleView = (record: Book) => {
    console.log('查看Book：', record);
    const detailPath = isAdminPath ? '/test/admin/book-detail' : '/test/book-detail';
    navigate(`${detailPath}/${record.id}`); // 根据当前路径跳转到不同的详情页
  };

  // 编辑Book
  const handleEdit = (record: Book) => {
    setEditingBook(record); // 设置当前编辑的Book
    setIsModalVisible(true);
  };

  // 删除Book
  const handleDelete = async (id: number) => {
    try {
      await deleteBook(id); // 调用封装的 deleteBook API
      const updatedData = data.filter((item) => item.id !== id);
      setData(updatedData); // 更新表格数据
      message.success('Book删除成功');
    } catch (error) {
      console.error('Error deleting Book:', error);
    }
  };

  // 处理分页改变
  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchTableData(pagination.current, pagination.pageSize);
  };

  // 扩展表格列，添加操作按钮
  const extendedColumns = [
    ...tableColumnsBook,
    {
      title: '操作',
      key: 'operate',
      render: (text: any, record: Book) => (
        <div style={{ textAlign: 'left' }}>
          <Space>
            <Button type="default" onClick={() => handleView(record)} style={{ marginRight: 8 }}>
              查看
            </Button>
            <Button type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>
              修改
            </Button>
            <Button type="primary" danger onClick={() => handleDelete(record.id)}>
              删除
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6">
      {!isAdminPath && (
        <h2 className="text-1xl font-bold text-center mb-4">Book Management</h2>
      )}
      <Button type="primary" onClick={showAddBookModal} className="mb-4">
        添加Book
      </Button>
      <Table
        columns={extendedColumns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <BookAddOrEditModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleAddOrEditBook}
        initialValues={editingBook}
        key={editingBook ? editingBook.id : 'add-book'} // 强制刷新
      />
    </div>
  );
};

export default BookListPage;
