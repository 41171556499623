/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import Mock, { generateMockTemplate } from '../../manual/mock';

// 定义规则映射（配置文件）
const rulesMap: any = {

  authId: '@increment', // 认证ID
  userId: '@increment', // 用户ID
  identityType: '@pick([1,2,3,10,11,12,13])', // 登录类型  1-登录账号 2-手机号 3-邮箱  10-微信  11-QQ 12-支付宝 13-微博
  identifier: '@guid', // 认证标识（用户名 ｜open_id）
  credential: '@password', // 密码凭据
  salt: '@name', // SALT
  sysType: '@pick(["MCH", "PLATFORM", "AGENT"])', // 所属系统
  createdAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 创建时间
  updatedAt: '@datetime("yyyy-MM-dd HH:mm:ss")', // 更新时间
};

// 生成 mock 数据模板
const userauthTemplate: any = generateMockTemplate(rulesMap);

// 生成 mock 数据
let mockUserAuthData = Mock.mock({
  'userauths|100': [userauthTemplate] // 生成100条数据
}).userauths;

// 获取UserAuth列表（模拟的分页）
export const getUserAuthMockData = (page: number, pageSize: number): Promise<any> => {
  const start = (page - 1) * pageSize;
  const records = mockUserAuthData.slice(start, start + pageSize);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        errorCode: 0,
        message: 'Request processed successfully',
        data: {
          records,
          total: mockUserAuthData.length,
          size: pageSize,
          current: page,
          pages: Math.ceil(mockUserAuthData.length / pageSize),
        },
      });
    }, 50);
  });
};

// 模拟添加UserAuth
export const mockAddUserAuth = (newUserAuth: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockUserAuthData.push({
      ...newUserAuth,
      authId: mockUserAuthData.length + 1,
      createdAt: now,
      updatedAt: now
    });
    setTimeout(() => resolve({ errorCode: 0, message: 'UserAuth added successfully' }), 50);
  });
};

// 模拟编辑UserAuth
export const mockEditUserAuth = (id: number, updatedUserAuth: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockUserAuthData = mockUserAuthData.map((item:any) =>
      (item.authId === id ? { ...item, ...updatedUserAuth, updatedAt: now } : item)
    );
    setTimeout(() => resolve({ errorCode: 0, message: 'UserAuth updated successfully' }), 50);
  });
};

// 模拟删除UserAuth
export const mockDeleteUserAuth = (id: number): Promise<any> => {
  return new Promise((resolve) => {
    mockUserAuthData = mockUserAuthData.filter((item:any) => item.authId !== id);
    setTimeout(() => resolve({ errorCode: 0, message: 'UserAuth deleted successfully' }), 50);
  });
};

// 获取UserAuth详情（模拟）
export const mockFetchUserAuthDetail = (id: number): Promise<any> => {
  const item = mockUserAuthData.find((item:any) => item.authId === id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (item) {
        resolve({ data: item }); // 返回找到的UserAuth
      } else {
        reject(new Error('UserAuth not found')); // 未找到UserAuth时返回错误
      }
    }, 50); // 模拟延迟50ms
  });
};
