/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/request';
import {
  getUserMockData,
  mockAddUser,
  mockEditUser,
  mockDeleteUser,
  mockFetchUserDetail
} from './mockUserData'; // 导入 Mock 函数

const USE_MOCK_DATA = true; // 切换 Mock 数据标志

// 获取User列表的请求
export const fetchUserList = (page: number, pageSize: number) => {
  if (USE_MOCK_DATA) {
    return getUserMockData(page, pageSize);
  }
  return getRequest('/api/v1/test/user/page', {
    page,
    size: pageSize,
  });
};

// 添加新User的请求
export const addUser = (data: any) => {
  if (USE_MOCK_DATA) {
    return mockAddUser(data);
  }
  return postRequest('/api/v1/test/user', data);
};

// 编辑User的请求
export const editUser = (id: number, data: any) => {
  if (USE_MOCK_DATA) {
    return mockEditUser(id, data);
  }
  return putRequest(`/api/v1/test/user/${id}`, data);
};

// 删除User的请求
export const deleteUser = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockDeleteUser(id);
  }
  return deleteRequest(`/api/v1/test/user/${id}`);
};

// 获取User详情的请求
export const fetchUserDetail = (id: number) => {
  if (USE_MOCK_DATA) {
    return mockFetchUserDetail(id);
  }
  return getRequest(`/api/v1/test/user/${id}`);
};
