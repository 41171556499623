/*
 * Copyright © 2024 上海火金网络科技有限公司. All rights reserved.
 * This is an automatically generated file. DO NOT MODIFY.
 *
 * Generated by: MiaoMa（BeraBuild）
 * Version: miaoma generator v1.0.0
 * Date: 2024-09-29
 *
 * Website: https://www.huojin.com
 */

import Mock, { generateMockTemplate } from '../../manual/mock';

// 定义规则映射（配置文件）
const rulesMap: any = {

  sysUserId: '@increment', // 用户ID
  loginUsername: '@word', // 登录用户名
  realname: '@cname', // 真实姓名
  telephone: '@bera_telephone', // 手机号
  sex: '@pick([1,2])', // 性别：1-男，2-女，0-未知
  avatarUrl: '@image("200x200", "#ADD8E6", "#FFF", "Avatar")', // 头像地址（可以为空）
  userNo: '@guid', // 员工编号
  inviteCode: '@string("upper", 8)', // 邀请码（可以为空）
  userType: '@pick([1, 2, 3, 11, 12])', // 用户类型：1-超级管理员，2-普通用户，3-拓展员，11-店长，12-店员
  teamId: '@natural(1, 5)', // 团队ID
  isTeamLeader: '@pick([0, 1])', // 是否队长：1-是，0-否
  state: '@pick([0, 1])', // 状态：1-启用，0-停用
  sysType: '@word(3, 5)', // 所属系统类型
  belongInfoId: '@string("upper", 5)', // 所属信息ID
  mfaBindState: '@pick([0, 1])', // MFA绑定状态：0-未绑定，1-已绑定
  mfaSecretKey: '@string(16)', // MFA验证秘钥（可以为空）
  safeWord: '@word(5, 10)', // 安全预留信息
  pwdExpiredTime: '@bera_iso_datetime', // 密码过期时间（ISO日期格式）
  lastResetPwdTime: '@bera_iso_datetime', // 最后一次重置密码时间（ISO日期格式）
  lastLoginTime: '@bera_iso_datetime', // 上次登录时间（ISO日期格式）
  createdAt: '@bera_iso_datetime', // 创建时间（ISO日期格式）
  updatedAt: '@bera_iso_datetime', // 更新时间（ISO日期格式）
};

// 生成 mock 数据模板
const userTemplate: any = generateMockTemplate(rulesMap);

// 生成 mock 数据
let mockUserData = Mock.mock({
  'users|100': [userTemplate] // 生成100条数据
}).users;

// 获取User列表（模拟的分页）
export const getUserMockData = (page: number, pageSize: number): Promise<any> => {
  const start = (page - 1) * pageSize;
  const records = mockUserData.slice(start, start + pageSize);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        errorCode: 0,
        message: 'Request processed successfully',
        data: {
          records,
          total: mockUserData.length,
          size: pageSize,
          current: page,
          pages: Math.ceil(mockUserData.length / pageSize),
        },
      });
    }, 50);
  });
};

// 模拟添加User
export const mockAddUser = (newUser: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockUserData.push({
      ...newUser,
      sysUserId: mockUserData.length + 1,
      createdAt: now,
      updatedAt: now
    });
    setTimeout(() => resolve({ errorCode: 0, message: 'User added successfully' }), 50);
  });
};

// 模拟编辑User
export const mockEditUser = (id: number, updatedUser: any): Promise<any> => {
  return new Promise((resolve) => {
    const now = new Date().toISOString();
    mockUserData = mockUserData.map((item:any) =>
      (item.sysUserId === id ? { ...item, ...updatedUser, updatedAt: now } : item)
    );
    setTimeout(() => resolve({ errorCode: 0, message: 'User updated successfully' }), 50);
  });
};

// 模拟删除User
export const mockDeleteUser = (id: number): Promise<any> => {
  return new Promise((resolve) => {
    mockUserData = mockUserData.filter((item:any) => item.sysUserId !== id);
    setTimeout(() => resolve({ errorCode: 0, message: 'User deleted successfully' }), 50);
  });
};

// 获取User详情（模拟）
export const mockFetchUserDetail = (id: number): Promise<any> => {
  const item = mockUserData.find((item:any) => item.sysUserId === id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (item) {
        resolve({ data: item }); // 返回找到的User
      } else {
        reject(new Error('User not found')); // 未找到User时返回错误
      }
    }, 50); // 模拟延迟50ms
  });
};
